import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './styles.module.scss';
import Icon from '../../components/Icon';

export default function PPModal(props) {
  const {
    open,
    handleClose,
    icon,
    modalHeading,
    subMsg,
    success,
    activeFileSubMsg,
    ...rest
  } = props;
  const headingClass = !success
    ? styles.modal_err_heading
    : styles.modal_success_heading;
  const iconStyles = !success ? styles.err_icon : styles.success_icon;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid={rest['data-testid']}>
      <div>
        <Box className={styles.box_style}>
          {!success ? (
            <div className={styles.cross_icon} onClick={handleClose}>
              <Icon name="pp_cross_icon" width="16" height="16" />
            </div>
          ) : (
            <></>
          )}
          <div className={styles.modal_container}>
            <div className={iconStyles}> {icon}</div>
            <p className={headingClass}> {modalHeading} </p>
            {subMsg && <p className={styles.modal_err_sub_text}>{subMsg} </p>}
            {activeFileSubMsg && (
              <p className={styles.modal_success_sub_text}>
                {activeFileSubMsg}
              </p>
            )}
          </div>
        </Box>
      </div>
    </Modal>
  );
}
