export const ROOT = '/';

export const DEFAULT_PATH = ROOT;

export const LOGIN_PATH = '/login';
export const SSO_REDIRECT = '/sso/redirect';

// const DEMAND_ANALYSIS_PATH = "/demand-analysis";
// const OVERRIDE_MANAGER_PATH = "/override-manager/*";
// const EVENT_CALENDAR_PATH = "/event-calendar";
export const PRODUCTION_PLANNING = '/production-planning';
export const DATA_INPUT_PATH = '/production-planning/data-input';
export const PRODUCTION_PLAN_PATH = '/production-planning/production-plan';
export const ARCHIVES_PATH = '/production-planning/archives';
export const RM_PLAN_PATH = '/production-planning/rm-plan';
export const CONFIG_PAGE_PATH = '/production-planning/config';
export const CONFIG_LIST_PATH = '/production-planning/config/list';
export const CONFIG_EDIT_PATH = '/production-planning/config/edit/:property';
export const CONFIG_ADD_PATH = '/production-planning/config/add';
