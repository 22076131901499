import React, { Fragment, useEffect, useRef } from 'react';
import { ACCEPT_FORMAT, UPLOADING_LABEL } from './constant';
import styles from './style.module.scss';
import PPButton from '../PPButton';
import { UPLOAD_BOX_TEXT } from '../../pages/ProductionPanning/DailyComponent/constants';
import FileDragandDrop from '../FIleDragandDrop';
import Icon from '../../components/Icon';

function PPUploadComponent(props) {
  /* De-structuring props */
  const {
    uploading,
    uploadBoxTitle,
    title,
    firstBtnLabel,
    firstIcon,
    handleFileUpload,
    selectedFile,
    handleEditFileOpen,
    uploadFile,
    dynamicSheetErrorMsg,
    updatedAt,
    dataTill,
    fileButtonDisabled,
    turnBluecolor,
    drag = false,
    dragProps = {},
  } = props;

  const uploadRef = useRef();

  useEffect(() => {
    if (uploadRef.current && !selectedFile) {
      uploadRef.current.value = null;
    }
  }, [selectedFile]);

  const DragContainer = drag ? FileDragandDrop : Fragment;

  return (
    <div data-testid={props?.['data-testid']} className={styles.container}>
      <div className={styles.upload_header_container}>
        <div className={styles.upload_header_main}>
          <div
            data-testid={`${props?.['data-testid']}-title`}
            className={styles.upload_title}>
            {title}
          </div>
          {dynamicSheetErrorMsg ? (
            <div className={styles.err_msg}>
              <Icon name="warning" width={15} height={14} />
              <span className={styles.err_msg}>{dynamicSheetErrorMsg}</span>
            </div>
          ) : (
            <div
              data-testid={`${props?.['data-testid']}_updatedAt`}
              className={styles.update_at_timestamp}>
              {updatedAt || ''}
            </div>
          )}
        </div>
        {dataTill && (
          <div
            data-testid={`${props?.['data-testid']}_data_till`}
            className={styles.upload_data_till}>
            {dataTill}
          </div>
        )}
      </div>
      <DragContainer {...dragProps}>
        <div className={styles.upload_action}>
          {turnBluecolor && uploadBoxTitle ? (
            <div
              data-testid={`${props?.['data-testid']}_file_name_label`}
              className={styles.uploaded_file_label}>
              {uploadBoxTitle || UPLOAD_BOX_TEXT}
            </div>
          ) : (
            <div
              data-testid={`${props?.['data-testid']}_file_name_label`}
              className={styles.upload_label}>
              {uploadBoxTitle || UPLOAD_BOX_TEXT}
            </div>
          )}
          {uploading && (
            <div className={styles.uploading}> {UPLOADING_LABEL}</div>
          )}
          {uploadFile ? (
            <div>
              <input
                data-testid={`${props?.['data-testid']}_file_upload_input`}
                className={styles.input_file_style}
                type="file"
                ref={uploadRef}
                onChange={() => handleFileUpload(uploadRef.current.files)}
                accept={ACCEPT_FORMAT}
              />
              <PPButton
                variant="contained"
                classes={{
                  root: fileButtonDisabled
                    ? styles.disable_browse_file_button
                    : styles.browse_file_button,
                }}
                endIcon={firstIcon}
                onClick={() => uploadRef.current.click()}>
                <span className={styles.browse_file_txt}>{firstBtnLabel}</span>
              </PPButton>
            </div>
          ) : (
            <div>
              <PPButton
                data-testid={`${props?.['data-testid']}_file_upload_button`}
                variant="contained"
                classes={{
                  root: fileButtonDisabled
                    ? styles.disable_browse_file_button
                    : styles.browse_file_button,
                }}
                endIcon={firstIcon}
                onClick={() => handleEditFileOpen()}>
                {' '}
                <span className={styles.browse_file_txt}>{firstBtnLabel}</span>
              </PPButton>
            </div>
          )}
        </div>
      </DragContainer>
    </div>
  );
}

export default PPUploadComponent;
