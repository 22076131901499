import { Tab, Tabs } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import Icon from '../../components/Icon';

const PPSideBar = (props) => {
  const {
    activeTab,
    onChange,
    className,
    tabs = [],
    'data-testid': testId,
  } = props;

  return (
    <>
      <div
        data-testid={testId}
        className={`${styles.sidebar_container} ${className}`}>
        <div className={styles.sidebar_content}>
          <div className={styles.sidebar_heading}> Production Planning </div>
          {activeTab && (
            <Tabs
              className={styles.tab_items_container}
              classes={{ indicator: styles.display_none }}
              orientation="vertical"
              variant="scrollable"
              value={activeTab}
              onChange={onChange}
              aria-label="Production Planning Tabs">
              {tabs.map((each) => {
                const isActiveTab = activeTab === each.url;
                return (
                  <Tab
                    key={each.url}
                    className={`${styles.tab_item} ${
                      isActiveTab && styles.active
                    }`}
                    iconPosition="start"
                    icon={
                      isActiveTab ? (
                        <Icon name="green_circle" width="12" height="12" />
                      ) : (
                        <Icon name="black_circle" width="12" height="12" />
                      )
                    }
                    value={each.url}
                    label={each.label}
                  />
                );
              })}
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
};

export default PPSideBar;
