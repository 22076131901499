import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Title } from '../../common/styles/base-styled-components.jsx';
import { TITLETYPOGRAPHY } from '../../common/typography';
import LiciousLogo from '../../assets/licious_logo.png';
import { useDispatch, useSelector } from 'react-redux';

import { clearToken } from '../../redux/saga/UserReducer/actions.js';

import { NO_USER } from '../../constants.js';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';
import { PRODUCTION_PLANNING } from '../../Routing/paths';
import { HEADER_TESTID } from './constants.js';
import Icon from '../Icon/index.jsx';

const HeaderContainer = styled.div`
  padding: 0 30px;
  display: grid;
  grid-template-columns: 10% 1fr;
  box-shadow: 0px 1px 0px #e4e7f0;

  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 1;
`;

const LogoWrapper = styled.div`
  align-self: center;
  cursor: pointer;
`;

const RightMenu = styled.div`
  justify-self: end;
  padding: 10px 0;
  display: flex;
  align-items: stretch;
`;
const UserWrapper = styled.div`
  cursor: pointer;
  /* padding-left: 20px; */
  display: flex;
  align-items: center;
  gap: 10px;
  .profile_icon svg .profile:hover {
    fill: #c92a2a;
  }
`;

const LogoutPopperWrapper = styled.div`
  width: 200px;
  background: white;
  border: 1px solid #e5e5e5;
`;

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authUserResponse } = useSelector((state) => state.authReducer);

  const name = authUserResponse?.email_id;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(clearToken());
  };
  const handleLogoClick = () => {
    navigate(PRODUCTION_PLANNING);
  };

  return (
    <HeaderContainer data-testid={HEADER_TESTID}>
      <LogoWrapper onClick={() => handleLogoClick()}>
        <img src={LiciousLogo} alt="Licious Logo" />
      </LogoWrapper>

      <RightMenu>
        <UserWrapper onClick={handleClick}>
          <div className="profile_icon">
            <Icon name="profile_icon" width="25" height="29" />
          </div>
          <Title
            size={TITLETYPOGRAPHY['title-2'].size}
            weight={TITLETYPOGRAPHY['title-2'].weight}>
            {name ? name : NO_USER}
          </Title>
          <Icon name="down_arrow_icon" width="14" height="9" />
        </UserWrapper>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={styles.popper_display}>
          <ClickAwayListener onClickAway={handleClose}>
            <LogoutPopperWrapper>
              <nav aria-label="secondary mailbox folders">
                <List>
                  <ListItem disablePadding>
                    <ListItemButton component="a" onClick={handleLogout}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="body1">Logout</Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </nav>
            </LogoutPopperWrapper>
          </ClickAwayListener>
        </Popper>
      </RightMenu>
    </HeaderContainer>
  );
};

export default Header;
