import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import SSORedirectUtils from './utils';

import { TOKEN_KEY, TOKEN_STATUS_KEY } from './constants';
import Loader from '../../common/Loader';
import { DEFAULT_PATH, LOGIN_PATH } from '../../Routing/paths';

function SSORedirect() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const token = searchParams.get(TOKEN_KEY);
  const tokenStatus = searchParams.get(TOKEN_STATUS_KEY);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [infoMsg, setInfoMsg] = useState();

  const handleWrongRedirect = useCallback(() => {
    navigate(DEFAULT_PATH, { replace: true });
  }, [navigate]);

  const handleRedirectFromSSO = useCallback(() => {
    try {
      const redirectTo = SSORedirectUtils.handleRedirectFromSSO(
        token,
        tokenStatus,
      );
      window.location.assign(redirectTo);
    } catch (e) {
      navigate(LOGIN_PATH, { replace: true });
      setIsSnackBarOpen(true);
      setInfoMsg({
        status: false,
        msg: e?.message || `Not an authorized user`,
      });
    }
  }, [token, tokenStatus, navigate]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  useEffect(() => {
    if (!tokenStatus) {
      handleWrongRedirect();
    } else {
      handleRedirectFromSSO();
    }
  }, [handleWrongRedirect, handleRedirectFromSSO, tokenStatus]);

  return (
    <div>
      <Loader />
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <div>
          {isSnackBarOpen && (
            <Alert
              onClose={handleSnackbarClose}
              severity={infoMsg?.status ? 'success' : 'error'}>
              {infoMsg?.msg}
            </Alert>
          )}
        </div>
      </Snackbar>
    </div>
  );
}

export default SSORedirect;
