import { Navigate, Outlet } from 'react-router';
import ProductionPlanning from '../pages/ProductionPanning';
import AppWrapper from '../pages/AppWrapper';
import Login from '../pages/Login/index';
import SSORedirect from '../pages/SSORedirect';
import DataInputComponent from '../pages/ProductionPanning/DataInputTab';
import ProductionPlanMenuPage from '../pages/ProductionPanning/ProductionPlanMenu';
import ArchivesPage from '../pages/Archives/loadableService';

//import RMPlanningPage from "../pages/RMPlan/RMPlanning";
import { HeaderData } from '../common/properties';

import {
  SSO_REDIRECT,
  LOGIN_PATH,
  ROOT,
  PRODUCTION_PLANNING,
  DATA_INPUT_PATH,
  PRODUCTION_PLAN_PATH,
  ARCHIVES_PATH,
  CONFIG_EDIT_PATH,
  CONFIG_PAGE_PATH,
  CONFIG_ADD_PATH,
  CONFIG_LIST_PATH,
  //RM_PLAN_PATH,
} from './paths';
import EditConfig from '../pages/ConfigManagement/EditConfig';
import ConfigListing from '../pages/ConfigManagement/ConfigList';
import AddConfig from '../pages/ConfigManagement/AddConfig';

export const ApplicationRoutes = [
  {
    path: SSO_REDIRECT,
    element: <SSORedirect />,
  },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: ROOT,
    element: <AppWrapper />,
    children: [
      {
        index: true,
        element: <Navigate to={HeaderData[0].route} replace />,
      },
      {
        path: PRODUCTION_PLANNING,
        element: <ProductionPlanning />,
        children: [
          {
            path: DATA_INPUT_PATH,
            element: <DataInputComponent />,
          },
          {
            path: PRODUCTION_PLAN_PATH,
            element: <ProductionPlanMenuPage />,
          },
          {
            path: ARCHIVES_PATH,
            element: <ArchivesPage />,
          },
          // will be enabled post QA signoff and API integration
          // {
          //   path: RM_PLAN_PATH,
          //   element: <RMPlanningPage />,
          // },
          {
            path: CONFIG_PAGE_PATH,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Navigate to={CONFIG_LIST_PATH} />,
              },
              {
                path: CONFIG_LIST_PATH,
                element: <ConfigListing />,
              },
              // {
              // will remove it if not required in future
              //   path: CONFIG_ADD_PATH,
              //   element: <AddConfig />,
              // },
              {
                path: CONFIG_EDIT_PATH,
                element: <EditConfig />,
              },
            ],
          },
        ],
      },
    ],
  },
];
