import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './style.module.scss';
import Icon from '../../components/Icon';

export default function PPAttentionModal(props) {
  const {
    open,
    handleClose,
    icon,
    modalHeading,
    subMsg,
    activeFile,
    fileCount,
    ...rest
  } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid={rest['data-testid']}>
      <div>
        <Box className={styles.box_style}>
          <div className={styles.cross_icon} onClick={handleClose}>
            <Icon name="pp_cross_icon" width="16" height="16" />
          </div>
          <div className={styles.modal_container}>
            <div className={styles.icon_style}> {icon}</div>
            <p className={styles.modal_err_heading}> {modalHeading} </p>
            <p className={styles.modal_err_sub_text}>
              {activeFile
                ? activeFile +
                  (fileCount > 0 ? '...(+' + fileCount + ') ' : ' ') +
                  subMsg
                : ''}
            </p>
          </div>
        </Box>
      </div>
    </Modal>
  );
}
