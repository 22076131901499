import PPButton from '../../common/PPButton';
import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { OPEN_BTN, SUB_TITLE } from './constants';
import GoogleSheetIcon from '../../assets/google_spreadsheet.png';
import Icon from '../Icon';
import SheetIcon from './SheetIcon';

function TabContent({
  className,
  data,
  onOpenSheet,
  timeStampConfig,
  subTitle = SUB_TITLE,
  title = '',
  regeneratePlan = <></>,
}) {
  return (
    <div className={`${className} ${styles.tab_content}`}>
      <div className={styles.left_section}>
        <div>
          <div className={styles.title_container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.sub_title}>{subTitle}</div>
          </div>
          {regeneratePlan}
          <div className={styles.time_stamp_container}>
            {Object.values(timeStampConfig).map((each) => {
              if (!data?.[each.key]) return null;
              return (
                <Fragment key={each.key}>
                  <span data-testid={`${each.key}_label`}>{each?.label}</span>
                  <span data-testid={`${each.key}_date`}>
                    {data[each.key]?.date}
                  </span>
                  <span data-testid={`${each.key}_time`}>
                    {data[each.key]?.time}
                  </span>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div className={styles.action_container}>
          <span className={styles.primary_button}>
            <PPButton
              component="a"
              disabled={!data.sheetUrl}
              href={data.sheetUrl || ''}
              target="_blank"
              children={OPEN_BTN}
              onClick={() => onOpenSheet(data.value)}
              className={styles.btn}
            />
          </span>
        </div>
      </div>
      <div className={styles.right_section}>
        <a
          href={data.sheetUrl || ''}
          target="_blank"
          onClick={() => onOpenSheet(data.value)}
          rel="noreferrer"
          className={styles.sheet_link}>
          <div className={styles.sheet_img_container}>
            <SheetIcon className={styles.spreadsheet_icon} />
            <div className={styles.spreadsheet_card}>
              <img
                src={GoogleSheetIcon}
                alt=""
                className={styles.spreadsheet_icon}
                onClick={() => onOpenSheet(data.value)}></img>
              <span className={styles.fig_caption}>{data?.sheetName}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default TabContent;
