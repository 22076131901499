import { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { PRODUCTION_PLANNING_TABS_CONFIG } from '../../common/PPSideBar/constants';
import { useSelector } from 'react-redux';
import { selectUserRoles } from '../../redux/UserReducer/selector';

export const useRBACTabs = () => {
  const userRoles = useSelector(selectUserRoles);
  const tabs = useMemo(
    () =>
      Object.values(PRODUCTION_PLANNING_TABS_CONFIG).filter((tab) => {
        return userRoles.filter((role) => tab.roles.includes(role)).length > 0;
      }),
    [userRoles],
  );

  const [activeTab, setActiveTab] = useState('');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const validTabPath = tabs.find((tab) =>
      matchPath({ path: tab.url, end: false }, pathname),
    )?.url;
    if (activeTab === '' && !validTabPath) {
      setActiveTab(tabs?.[0]?.url);
      navigate(tabs?.[0]?.url);
    } else {
      setActiveTab(validTabPath);
    }
  }, [activeTab, pathname, navigate, setActiveTab, tabs]);

  return [tabs, activeTab, setActiveTab];
};
