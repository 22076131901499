import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../common/Table/Table';
import { selectConfigData } from '../../redux/ConfigManagement/selectors';
import { getConfig } from '../../redux/saga/ConfigManagement/actions';
import { CONFIG_TABLE_COLUMN_CONFIG } from './constants';
import TableBody from '../../common/Table/TableBody';
import TableRow from '../../common/Table/TableRow';
import TableCell from '../../common/Table/TableCell';
import TableHeader from '../../common/Table/TableHeader';
import { generatePath, useNavigate } from 'react-router-dom';
import { CONFIG_EDIT_PATH } from '../../Routing/paths';
import style from './style.module.scss';
import TableHeadCell from '../../common/Table/TableHeadCell';
import { TablePagination } from '../../common/Table/TablePagination';
import { usePaginate } from '../../common/Table/usePaginate';
import Loader from '../../common/Loader';
import useTableSearch from '../../common/Table/useTableSearch';
import EditInput from './EditInput';
import { Button, InputAdornment } from '@mui/material';
import Icon from '../../components/Icon';

const EditButton = ({ row, col, onClick }) => {
  return (
    <button className={style.edit_button} onClick={() => onClick(row, col)}>
      <Icon name="EditIcon" width="20" height="20" />
    </button>
  );
};

const CellContent = ({ row, col, componentProps }) => {
  if (col.key === 'edit') {
    return <EditButton row={row} col={col} {...componentProps?.[col.key]} />;
  } else {
    return row[col.key];
  }
};

const ConfigListing = () => {
  let pageSize = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, data } = useSelector(selectConfigData);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const searchAttributes = ['key'];
  const searchedData = useTableSearch(data, search, searchAttributes);

  const [paginatedData, pages] = usePaginate(
    searchedData,
    pageSize,
    currentPage,
  );

  useEffect(() => {
    dispatch(getConfig({ id: '' }));
  }, [dispatch]);

  const goToEditConfig = (row, col) => {
    navigate(generatePath(CONFIG_EDIT_PATH, { property: row?.id }));
  };
  const componentProps = {
    edit: { onClick: goToEditConfig },
  };

  // const addConfig = () => navigate(CONFIG_ADD_PATH);

  const handlePageClick = (page) => {
    const newPage = Math.max(page - 1, 0);
    setCurrentPage(newPage);
  };

  const firstRowRef = useRef(null);

  if (error) {
    return <div>{error?.error}</div>;
  }

  const clearSearch = () => setSearch('');
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(0); //reset current page if searching
  };

  return (
    <div className={style.config_list_container}>
      <h2 className={style.title}>Config Management</h2>

      {/* <PPButton onClick={addConfig}>Add Config</PPButton> */}
      <Loader showLoader={loading}>
        {data.length > 0 && (
          <EditInput
            classes={{ root: style.search_field }}
            value={search}
            onChange={handleSearch}
            placeholder="Search by property"
            InputProps={{
              classes: {
                inputAdornedEnd: style.adorned_input,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {search && (
                    <Button
                      classes={{ root: style.clear_search_btn }}
                      disableRipple
                      onClick={clearSearch}>
                      <Icon name="pp_cross_icon" width="16" height="16" />
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        <Table className={style.config_table}>
          <TableHeader>
            {CONFIG_TABLE_COLUMN_CONFIG.map((col) => (
              <TableHeadCell key={col.title} style={{ width: col?.width }}>
                {col.title}
              </TableHeadCell>
            ))}
          </TableHeader>
          <TableBody>
            {paginatedData.map((row, idx) => (
              <TableRow
                ref={idx === 0 && firstRowRef ? firstRowRef : null}
                key={row.uid}>
                {CONFIG_TABLE_COLUMN_CONFIG.map((col) => {
                  return (
                    <TableCell
                      style={{ maxWidth: col?.width }}
                      className
                      key={col.key}>
                      <CellContent
                        row={row}
                        col={col}
                        componentProps={componentProps}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {paginatedData?.length < pageSize && (
              <tr>
                <td
                  style={{
                    height: `${
                      (pageSize - paginatedData?.length) *
                      firstRowRef?.current?.getBoundingClientRect()?.height
                    }px`,
                  }}></td>
              </tr>
            )}
          </TableBody>
        </Table>
        <TablePagination
          className={style.pagination}
          currentPage={currentPage}
          pages={pages}
          dataLength={data?.length ?? 0}
          handlePageClick={handlePageClick}
        />
      </Loader>
    </div>
  );
};

export default ConfigListing;
