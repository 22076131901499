import { DEFAULT_PATH, LOGIN_PATH } from '../../Routing/paths';
import StorageService from '../../utils/storageService';
import {
  REDIRECT_URL_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
} from '../LoginProvider/constants';

function handleRedirectFromSSO(token, tokenStatus) {
  const success = tokenStatus ? tokenStatus === 'true' : false;
  if (token && success) {
    StorageService.setValue(USER_TOKEN_STORAGE_KEY, token);
    const redirectTo =
      StorageService.getValue(REDIRECT_URL_STORAGE_KEY) || DEFAULT_PATH;
    StorageService.removeKey(REDIRECT_URL_STORAGE_KEY);
    if (redirectTo === LOGIN_PATH) return DEFAULT_PATH;
    return redirectTo;
  } else {
    throw new Error();
  }
}

const SSORedirectUtils = {
  handleRedirectFromSSO,
};

export default SSORedirectUtils;
