export const COLOR_PRIMARY = '#C92A2A';
export const COLOR_WHITE = '#FFFFFF';

export const COLOR_TEXT = {
  DARK: '#212529',
  DARKEST: '#000000',
  LIGHT: '#868E96',
  HYPERLINK: '#1C7ED6',
  HIGHLIGHT: '#FFFF00',
  ALERT_SUCCESS: '#2B8A3E',
};

export const COLOR_TEXT_HOVER = {
  DARK: '#3e464d',
};

export const COLOR_BACKGROUND = {
  BG: '	#F8F9FA',
  HIGHLIGHT: '#FFF9DB',
  REFRESH: '#6c5ce7',
};

export const COLOR_TOGGLE = {
  BG: '#F1F3F5',
  HIGHLIGHT: '#FFFFFF',
};

export const COLOR_POPUP = {
  BG: '#D3F9DB',
  CHECK: '#2B8A3E',
};

export const COLOR_ALERT = {
  BG: '#FFF9DB',
  ALERT: '#E67700',
};
