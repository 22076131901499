import React from 'react';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

export default function PPButton(props) {
  const {
    endIcon,
    startIcon,
    children,
    onClick,
    className,
    isAnimating,
    ...rest
  } = props;
  return (
    <Button
      variant="contained"
      className={`${className} ${isAnimating ? styles.spinner : ''}`}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      {...rest}>
      {children}
    </Button>
  );
}
