import React from 'react';
import PPSlideBar from '../../../common/PPSlideBar';
import styles from '../styles.module.scss';
import { DATA_INPUT_LABEL, TABS_CONFIG } from './constant';

const DataInputComponent = () => {
  return (
    <div>
      <h2 className={styles.page_heading}> {DATA_INPUT_LABEL} </h2>
      <div className={styles.pp_slidebar_section}>
        <PPSlideBar tabs={TABS_CONFIG} />
      </div>
    </div>
  );
};

export default DataInputComponent;
