import { DEFAULT_ERROR_MESSAGE } from '../../../constants';
import { apiCall } from '../../../utils/axiosAPIWrapper';
import { SYNC_PRODUCTION_PLAN_TAB_DATA } from '../../../utils/serviceUrls';
import { format } from 'date-fns';
const syncInputData = (body, headers) => {
  return apiCall('POST', SYNC_PRODUCTION_PLAN_TAB_DATA, body, headers).catch(
    (e) => {
      const { data } = e || {};
      const { error, message } = data?.errors?.[0] || {};
      return Promise.reject(
        message ||
          error ||
          data?.message ||
          e?.message ||
          DEFAULT_ERROR_MESSAGE,
      );
    },
  );
};

const formatDate = (date, dateFormat) => format(date, dateFormat);

function getTimestampDetails(dateValue) {
  if (!dateValue) return null;
  const date = new Date(dateValue);
  return {
    date: formatDate(date, 'd-MMM-yyyy'),
    time: formatDate(date, 'hh:mm a'),
  };
}

const ProductionPlanMenuUtils = {
  syncInputData,
  getTimestampDetails,
  formatDate,
};

export default ProductionPlanMenuUtils;
