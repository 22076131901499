import { STATUS_TAG_CONFIG } from './constants';
import styles from './styles.module.scss';

const InventoryStatusTag = ({ status = 'low' }) => {
  const statusConfig = STATUS_TAG_CONFIG?.[status] ?? STATUS_TAG_CONFIG['low'];
  return (
    <span className={`${styles.inv_status} ${statusConfig.class}`}>
      {statusConfig.text}
    </span>
  );
};

export default InventoryStatusTag;
