import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styles from './style.module.scss';
import DailyComponent from '../../pages/ProductionPanning/DailyComponent';
import MasterComponent from '../../pages/ProductionPanning/MasterComponent';
import OccassionallyTab from '../../pages/ProductionPanning/Occassionally';
import RBACGuard from '../Guard/Guard';
import { useTabsWithPermissions } from '../../utils/useTabsWithPermissions';
import { useSelector } from 'react-redux';
import { selectUserRoles } from '../../redux/UserReducer/selector';

export default function PPSlideBar({ tabs }) {
  const [value, setValue] = React.useState('daily');
  const userRoles = useSelector(selectUserRoles);
  const tabWithPermissions = useTabsWithPermissions(tabs, userRoles);
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={styles.main_box}>
      <TabContext value={value}>
        <Box className={styles.tab_list_ctn}>
          <TabList onChange={handleChange}>
            {tabWithPermissions.map((tabConfig) => (
              <Tab
                key={tabConfig.id}
                label={tabConfig.label}
                value={tabConfig.id}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel value={'daily'} classes={{ root: styles.tab_panel }}>
          <DailyComponent />
        </TabPanel>
        <TabPanel value={'master'} classes={{ root: styles.tab_panel }}>
          <MasterComponent />
        </TabPanel>
        <TabPanel value={'occassionally'} classes={{ root: styles.panel }}>
          <OccassionallyTab />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
