import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSageMiddleware from 'redux-saga';
import rootReducer from './reducer';
import rootSaga from './saga/sagas';

const sagaMiddleware = createSageMiddleware();
let middlewares = [];
switch (process.env.NODE_ENV) {
  case 'development':
    middlewares = [sagaMiddleware, logger];
    break;
  case 'production':
    middlewares = [sagaMiddleware];
    break;
  default:
    break;
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
if (['development', 'production'].includes(process.env.NODE_ENV)) {
  sagaMiddleware.run(rootSaga);
}
