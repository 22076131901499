import { call, put, takeLatest } from 'redux-saga/effects';
import { DEFAULT_ERROR_MESSAGE } from '../../../constants';
import {
  ADD_CONFIG,
  EDIT_CONFIG,
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_ERROR,
} from './constants';

import { apiCall } from '../../../utils/axiosAPIWrapper';
import { CONFIG_URL } from '../../../utils/serviceUrls';
import { getConfig } from './actions';

function* GetConfig({ payload }) {
  const { id = '' } = payload;
  try {
    const res = yield call(
      apiCall,
      'GET',
      id ? `${CONFIG_URL}/${id}` : CONFIG_URL,
    );
    yield put({ type: GET_CONFIG_SUCCESS, payload: { id, data: res } });
  } catch (err) {
    yield put({
      type: GET_CONFIG_ERROR,
      payload: {
        id: id,
        error: err?.errors?.[0]?.message || DEFAULT_ERROR_MESSAGE,
      },
    });
  }
}
function* AddConfig() {
  // Add Config
}
function* EditConfig({ payload }) {
  const { key, value, id } = payload;
  try {
    const body = {
      key: key,
      value: value.trim(),
    };

    yield call(apiCall, 'PUT', `${CONFIG_URL}/${id}`, body);
  } catch (error) {
  } finally {
    yield put(getConfig({ id }));
  }
}

export default function* watcher() {
  yield takeLatest(GET_CONFIG, GetConfig);
  yield takeLatest(ADD_CONFIG, AddConfig);
  yield takeLatest(EDIT_CONFIG, EditConfig);
}
