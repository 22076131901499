import { Modal } from '@mui/material';
import style from './style.module.scss';
import PPButton from '../PPButton';
import Loader from '../Loader';
import PPModal from '../PPModal';
import { useEffect } from 'react';
import { FAILED_REGENERATION, REGENERATION_SUCCESS } from './constants';
import Icon from '../../components/Icon';

const REGNERATE_PLAN_TITLE = 'Re-generate Production Plan';
const REGENERATE_CONFIRMATION = 'Are you sure you want to proceed ?';
const getRegenerateMessage = (planDate) =>
  `This will regenerate the production plan for ${planDate}`;

const PPRegeneratePlanConfirmationModal = ({
  open,
  onClose,
  productionPlanDate,
  regeneratePlan,
  regenerateState: { loading, success, error } = {},
}) => {
  const initialized = !loading && !success && !error;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [onClose, success]);

  const getProductionPlanMessage = (prefix, date) => `${prefix} ${date}`;

  return (
    <>
      {initialized && (
        <Modal open={open}>
          <div className={style.modal_container}>
            <button
              data-testid="close_icon"
              className={style.modal_close}
              onClick={onClose}>
              <Icon name="x-circle" width="16px" height="16px" />
            </button>
            <div className={style.modal_content}>
              <Icon name="warning" height={60} width={60} />
              <p className={style.modal_title}>{REGNERATE_PLAN_TITLE} ?</p>
              <p className={style.modal_body}>
                {getRegenerateMessage(productionPlanDate)}
              </p>
              <p className={style.modal_body}>{REGENERATE_CONFIRMATION}</p>
              <div>
                <PPButton
                  variant="contained"
                  color="primary"
                  className={style.cancel_regenerate}
                  onClick={onClose}>
                  Cancel
                </PPButton>
                <PPButton
                  variant="outlined"
                  className={style.continue_regenerate}
                  onClick={regeneratePlan}>
                  Yes, Continue
                </PPButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {loading && (
        <Modal open={!!loading}>
          <div className={style.modal_content}>
            <Loader showLoader={true}></Loader>
          </div>
        </Modal>
      )}
      {error && (
        <PPModal
          open={!!error}
          handleClose={onClose}
          icon={<Icon name="warning" height={80} width={80} />}
          modalHeading={getProductionPlanMessage(
            FAILED_REGENERATION,
            productionPlanDate,
          )}
          success={!error}
        />
      )}
      {success && (
        <PPModal
          open={!!success}
          icon={<Icon name="green_tick" height={80} width={80} />}
          modalHeading={getProductionPlanMessage(
            REGENERATION_SUCCESS,
            productionPlanDate,
          )}
          success={success}
        />
      )}
    </>
  );
};
export default PPRegeneratePlanConfirmationModal;
