import Icon from '../../components/Icon';
import { CONTACT_TECH_SUPPORT, UNAUTHORISED_ACCESS } from './constants';
import styles from './style.module.scss';

const NoAccess = ({
  title = UNAUTHORISED_ACCESS,
  subMessage = CONTACT_TECH_SUPPORT,
}) => {
  return (
    <div className={styles.no_access_container}>
      <Icon name="UnauthorizedAccess" width="146" height="88" />
      <p className={styles.title}>{title}</p>
      <p className={styles.subMessage}>{subMessage}</p>
    </div>
  );
};

export default NoAccess;
