import {
  GET_INVENTORY_STATUS_DATA,
  GET_PRODUCTION_PLAN_TAB_METADATA,
  GET_PRODUCTION_PLAN_TAB_METADATA_SUCCESS,
} from './constants';

export const fetchProductionPlanTabMetadata = () => {
  return {
    type: GET_PRODUCTION_PLAN_TAB_METADATA,
  };
};

export const fetchProductionPlanTabMetadataSuccess = (payload) => {
  return {
    type: GET_PRODUCTION_PLAN_TAB_METADATA_SUCCESS,
    payload,
  };
};

export const fetchInventoryStatusData = (payload) => {
  return {
    type: GET_INVENTORY_STATUS_DATA,
    payload,
  };
};
