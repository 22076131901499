import { css } from 'styled-components';

import { COLOR_BACKGROUND } from '../colors';

export const LeftContainerMixin = css`
  background: ${COLOR_BACKGROUND.BG};
`;

export const RightContainerMixin = css`
  padding: 20px 30px;
`;

export const TypographyMixin = css`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
  font-weight: ${(props) => props.weight};
`;

export const FullFlexCenterAlignMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexHorizantalSmallGapMixin = css`
  display: flex;
  gap: 10px;
`;
