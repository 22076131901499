import { createReducer } from '../../utils/createReducer';
import {
  GET_PRODUCTION_PLAN_INPUTS_ERROR,
  GET_PRODUCTION_PLAN_INPUTS_SUCCESS,
  GET_PRODUCTION_PLAN_INPUTS,
  UPDATE_MASTER_PLAN_INPUTS,
} from './constants';

const INITIAL_STATE = {
  productionPlanInputsData: {},
  productionPlanInputsError: '',
  productionPlanInputsLoading: false,
};

const productionPlanningReducer = {
  [GET_PRODUCTION_PLAN_INPUTS]: (state, action) => {
    return {
      ...state,
      productionPlanInputsData: {},
      productionPlanInputsLoading: true,
    };
  },
  [GET_PRODUCTION_PLAN_INPUTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      productionPlanInputsData: action.payload,
      productionPlanInputsLoading: false,
    };
  },
  [GET_PRODUCTION_PLAN_INPUTS_ERROR]: (state, action) => {
    return {
      ...state,
      productionPlanInputsError: action.payload,
      productionPlanInputsLoading: false,
    };
  },
  [UPDATE_MASTER_PLAN_INPUTS]: (state, { payload }) => {
    return {
      ...state,
      productionPlanInputsData: {
        ...state.productionPlanInputsData,
        inputMetadata: state.productionPlanInputsData.inputMetadata.map(
          (input) => {
            if (input.id === payload.id) {
              return { ...input, lastUpdatedAt: payload.lastUpdatedAt };
            } else {
              return input;
            }
          },
        ),
      },
    };
  },
};

export default createReducer(INITIAL_STATE, productionPlanningReducer);
