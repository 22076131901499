import styled from 'styled-components';
import {
  TypographyMixin,
  LeftContainerMixin,
  RightContainerMixin,
  FullFlexCenterAlignMixin,
} from './base-styled-mixins';

/* MUI Custom Components Imports */
import { styled as MuiStyled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { COLOR_PRIMARY, COLOR_TEXT, COLOR_TEXT_HOVER } from '../colors';
import { TITLETYPOGRAPHY } from '../typography';

/* MUI Customs */
export const StyledPrimaryButton = MuiStyled(Button)({
  outline: 'none',
  boxShadow: 'none',
  background: COLOR_PRIMARY,
  borderRadius: '8px',
  border: '1px solid #DEE2E6',
  padding: '16px 24px',
  color: '#fff',
  textTransform: 'none',
  fontFamily: 'Lato',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '19px',
  '&:hover': {
    background: '#C5003E',
  },
});

export const StyledCTAButton = MuiStyled(Button)({
  textTransform: 'none',
  padding: '12px 24px',
  background: COLOR_TEXT.DARK,
  fontFamily: 'Lato',
  fontSize: `${TITLETYPOGRAPHY['title-2'].size}px`,
  fontWeight: '600',
  borderRadius: '8px',

  '&:hover': {
    background: COLOR_TEXT_HOVER.DARK,
  },
});

export const StyledSyncButton = MuiStyled(LoadingButton)({
  textTransform: 'none',
  padding: '12px 24px',
  fontSize: `${TITLETYPOGRAPHY['title-2'].size}px`,
  fontWeight: '600',
  color: '#AEB3BA',
  border: '1px solid #AEB3BA',
  borderRadius: '8px',

  '&:hover': {
    color: '#797d82',
    border: '1px solid #797d82',
    background: 'none',
  },
});

export const Container = styled.div`
  width: 100%;
  max-height: 85vh;

  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  height: 83vh;
  display: grid;
  grid-template-columns: 25% 1fr;
`;

export const MainLoginPageContainer = styled.div`
  height: 100vh;
  ${FullFlexCenterAlignMixin}
`;

/* Typography Base Components */
export const Heading1 = styled.h1`
  ${TypographyMixin}
  line-height: 25px !important;
  margin: 0px !important;
  position: sticky;
  left: 0px;

  ${(props) => props.customStyles && props.customStyles}
`;

export const Heading2 = styled.h2`
  ${TypographyMixin}
  line-height: 35px !important;
  margin: 0px !important;
`;

export const Heading3 = styled.h3`
  ${TypographyMixin}
  ${(props) => props.customStyles}

  line-height: 35px !important;
  margin: 0px !important;
`;

export const Title = styled.span`
  ${TypographyMixin}

  ${(props) => props.customStyles && props.customStyles}
`;

export const Paragraph = styled.p`
  ${TypographyMixin}
  margin: 0 !important;

  ${(props) => props.customStyles && props.customStyles}
`;

export const ChildTwoCustomiseContainer = styled.div`
  background-color: #f8f9fa;
  height: 75%;
  border-radius: 8px;
  overflow-y: scroll;
  margin-top: 20px;
  padding: 10px;
`;
export const ChildTwoCustomiseCheckbox = styled.div`
  padding: 5px;
`;

/* Demand Analysis SideBar  */
export const TitleHeaderWrapper = styled.div`
  &:not(:first-child) {
    padding-top: 20px;
  }

  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const SidebarTitleWrapper = styled.div`
  padding: 10px 0;
  padding-bottom: 20px;
`;
export const SidebarTitle = styled.div`
  display: flex;
  flex-direction: column;
  & > h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #212529;
  }
  & > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    /* Gray/06 */
    color: #868e96;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #212529;
  }

  & > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    /* Gray/06 */
    color: #868e96;
  }
`;

export const SidebarFilterListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    /* Gray/06 */
    color: #868e96;
  }
`;

/* Override Manager Base Components */
export const TimelineDetailContainer = styled.div`
  padding: 10px 30px;
`;

export const TimelineDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const TimelineDetailHeaderTitleAndCustomiseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TimelineDetailHeaderButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.cols}, 1fr);
  column-gap: 10px;
`;

export const TimelineDetailFooter = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

/* Demand Analysis Base Components */
export const DemandAnalysisFilterSection = styled.div`
  ${LeftContainerMixin}
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DemandAnalysisMainSection = styled.div`
  ${RightContainerMixin}

  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow: auto;
`;

export const DemandAnalysisHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DemandAnalysisHeaderTitleAndCustomiseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DemandAnalysisActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomTimelineContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const DemandAnalysisViewsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const DemandAnalysisVisualizationSection = styled.div`
  padding: 10px 0;
`;

export const SectionContainer = styled.div`
  width: 100%;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  /* Custom Styles */
  ${(props) => props.customStyles && props.customStyles}
`;
