import { CLEAR_TOKEN, VERIFY_TOKEN, VERIFY_TOKEN_COMPLETE } from './constants';

export function verifyTokenAction() {
  return {
    type: VERIFY_TOKEN,
  };
}

export function verifyTokenCompleteAction(payload) {
  return {
    type: VERIFY_TOKEN_COMPLETE,
    payload,
  };
}

export function clearToken() {
  return {
    type: CLEAR_TOKEN,
  };
}
