import { PLANNING_ROLES } from '../../LoginProvider/constants';

export const TABS_CONFIG = [
  {
    id: 'daily',
    label: 'Daily',
    roles: [PLANNING_ROLES.PLANNER, PLANNING_ROLES.SUPER_PLANNER],
  },
  {
    id: 'occassionally',
    label: 'Occassionally',
    roles: [PLANNING_ROLES.SUPER_PLANNER],
  },
  {
    id: 'master',
    label: 'Master',
    roles: [PLANNING_ROLES.PLANNER, PLANNING_ROLES.SUPER_PLANNER],
  },
];
export const DATA_INPUT_LABEL = 'Data Input';
