export const selectInventoryStatusData = (state) => {
  return state.productionPlanTabReducer.inventoryStatusData || [];
};

export const selectInventoryStatusError = (state) => {
  return state.productionPlanTabReducer.inventoryStatusError || '';
};

export const selectInventoryStatusLoading = (state) => {
  return state.productionPlanTabReducer.inventoryStatusLoading || false;
};
