export const PAGE_TITLE = 'Production Planning';
export const TAB_CONTENT_TITLE = 'Production plan';
export const SYNC_BTN = 'Sync';
export const REGENERATE_BTN = 'Re-Gen Plan';
export const NOTIFY = 'Notify PC';
export const TIME_STAMP_CONFIG = {
  PLAN_GENERATOR: {
    label: 'Plan Generated at - ',
    key: 'planGenerated',
  },
  PLAN_UPDATED: {
    label: 'Plan Updated at - ',
    key: 'planUpdated',
  },
  INVENTORY_UPDATED: {
    label: 'Inventory Updated at - ',
    key: 'inventoryUpdated',
  },
};
