import style from './style.module.scss';

const TableCell = ({ children, ...rest }) => {
  return (
    <td {...rest} className={style.table_cell}>
      {children}
    </td>
  );
};

export default TableCell;
