import styles from './styles.module.scss';
export const CITY_INVENTORY_STATUS_TABLE = {
  HEADERS: [
    {
      key: 'category',
      text: 'Category',
      class: styles.category_header,
    },
    {
      key: 'skuUpdate',
      text: 'SKU Update',
      class: styles.sku_update_header,
    },
    {
      key: 'status',
      text: 'Status',
      class: styles.status_header,
    },
  ],
};

export const STATUS_TAG_CONFIG = {
  low: { class: styles.inv_status_low, text: 'Attention' },
  medium: { class: styles.inv_status_medium, text: 'In Progress' },
  high: { class: styles.inv_status_high, text: 'Complete' },
};

export const DOWNLOAD_LOG = 'Download Log';
export const INV_DOWNLOAD_FAILED_TITLE = 'Failed to download';
export const INV_DOWNLOAD_FAILED_SUB_MSG =
  'Failed to download inventory status log';
