import { createReducer } from '../../../utils/createReducer';
import {
  GET_INVENTORY_STATUS_DATA,
  GET_INVENTORY_STATUS_DATA_COMPLETE,
  GET_PRODUCTION_PLAN_TAB_METADATA,
  GET_PRODUCTION_PLAN_TAB_METADATA_SUCCESS,
  SET_PRODUCTION_PLAN_UPDATED_AT,
} from './constants';

const INITIAL_STATE = {
  initialised: false,
  loading: false,
  error: false,
  data: { list: [] },
  inventoryStatusLoading: false,
  inventoryStatusError: '',
  inventoryStatusData: [],
};

const ProductionPlanTabReducerConfig = {
  [GET_PRODUCTION_PLAN_TAB_METADATA]: (state, action) => {
    return {
      ...state,
      loading: true,
      error: false,
    };
  },
  [GET_PRODUCTION_PLAN_TAB_METADATA_SUCCESS]: (state, action) => {
    return {
      ...state,
      initialised: true,
      loading: false,
      ...action.payload,
    };
  },
  [SET_PRODUCTION_PLAN_UPDATED_AT]: (state, action) => {
    const { id, planUpdated, productionPlanId, inventoryUpdated } =
      action.payload;
    const updatedList =
      state?.data?.list.map((pc) => {
        if (pc?.id === id) {
          return {
            ...pc,
            planUpdated: planUpdated || pc?.planUpdated,
            productionPlanId: productionPlanId || pc?.productionPlanId,
            inventoryUpdated: inventoryUpdated || pc?.inventoryUpdated,
          };
        } else {
          return pc;
        }
      }) ||
      state?.data?.list ||
      [];
    return {
      ...state,
      data: { list: updatedList },
    };
  },
  [GET_INVENTORY_STATUS_DATA]: (state) => {
    return {
      ...state,
      inventoryStatusLoading: true,
      inventoryStatusError: false,
      inventoryStatusData: [],
    };
  },
  [GET_INVENTORY_STATUS_DATA_COMPLETE]: (state, { payload }) => {
    return { ...state, inventoryStatusLoading: false, ...payload };
  },
};

const ProductionPlanTabReducer = createReducer(
  INITIAL_STATE,
  ProductionPlanTabReducerConfig,
);

export default ProductionPlanTabReducer;
