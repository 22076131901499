import { PRODUCTION_PLAN } from '../../../constants';
import {
  SYNC_DEMAND_PLAN_URL,
  UPLOAD_DEMAND_PLAN_URL,
  UPLOAD_INTERHUB_DISPATCH_PLAN,
} from '../../../utils/serviceUrls';
import { format } from 'date-fns';
import { SUCCESS } from './constants';

export const demandPlanFileUpload = (token, uploadedBy, data = {}) => {
  const url = UPLOAD_DEMAND_PLAN_URL;
  return fetch(url, {
    headers: {
      token: token,
      'X-REQUESTED-APP': PRODUCTION_PLAN,
      'X-REQUESTED-BY': uploadedBy,
      'Access-Control-Allow-Origin': '*',
    },
    method: 'POST',
    body: data,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response?.json());
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const demandFileSync = (token, uploadedBy, data = {}) => {
  const url = SYNC_DEMAND_PLAN_URL;
  return fetch(url, {
    headers: {
      token: token,
      'X-REQUESTED-APP': PRODUCTION_PLAN,
      'X-REQUESTED-BY': uploadedBy,
      'content-type': 'application/json; charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response.json());
      }
    })
    .then((response) => {
      var succMap = {};
      if (response.status === SUCCESS) {
        succMap = response?.data.reduce((acc, curr) => {
          acc[curr.inputId] = curr;
          return acc;
        }, {});
        return {
          ...response,
          data: succMap,
        };
      } else {
        return {
          ...response,
        };
      }
    })
    .catch((error) => {
      return error;
    });
};

export const masterFileSync = (token, uploadedBy, data = {}) => {
  const url = SYNC_DEMAND_PLAN_URL;
  return fetch(url, {
    headers: {
      token: token,
      'X-REQUESTED-APP': PRODUCTION_PLAN,
      'X-REQUESTED-BY': uploadedBy,
      'content-type': 'application/json; charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
    method: 'POST',
    body: JSON.stringify({ inputIds: data.inputIds }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response.json());
      }
    })
    .catch((error) => {
      return error;
    });
};

export const DPformatDate = (date) => {
  if (!date) return date;
  return 'Uploaded at ' + format(new Date(date), 'do MMM, hh:mm a');
};

export const formatDate = (date) => {
  if (!date) return date;
  return 'Updated at ' + format(new Date(date), 'do MMM, hh:mm a');
};
export const formatDataTill = (date) => {
  if (!date) return date;
  return 'Data till ' + format(new Date(date), 'do MMM');
};

export const interhubPlanUpload = async (token, uploadedBy, data = {}) => {
  const url = `${UPLOAD_INTERHUB_DISPATCH_PLAN}?requestedBy=${uploadedBy}`;
  return fetch(url, {
    headers: {
      token: token,
      'X-REQUESTED-APP': PRODUCTION_PLAN,
      'X-REQUESTED-BY': uploadedBy,
      'Access-Control-Allow-Origin': '*',
    },
    method: 'POST',
    body: data,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response?.json());
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
