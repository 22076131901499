import { Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import LoginProvider from '../LoginProvider';
import AuthorizedAccess from '../AuthorizedAccess';

function AppWrapper() {
  return (
    <LoginProvider>
      <Header />
      <AuthorizedAccess>
        <Outlet />
      </AuthorizedAccess>
    </LoginProvider>
  );
}

export default AppWrapper;
