import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALLOWED_ROLES } from '../LoginProvider/constants';
import NoAccess from './NoAccess';
import { clearToken } from '../../redux/saga/UserReducer/actions';

const AuthorizedAccess = ({ children }) => {
  const [isUserAuthorized, setUserAuthorized] = useState(false);
  const { authUserResponse } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const loggedInRef = useRef(true);

  useEffect(() => {
    const authorized = Boolean(
      authUserResponse.roles.find((role) => ALLOWED_ROLES.includes(role)),
    );
    setUserAuthorized(authorized);
    const logout = () => {
      if (loggedInRef.current) {
        dispatch(clearToken());
        loggedInRef.current = false;
      }
    };
    if (!authorized) {
      /*logout user on page refresh if user does not have correct user roles*/
      window.addEventListener('beforeunload', logout);
    }

    return () => {
      if (!authorized) {
        window.removeEventListener('beforeunload', logout);
      }
    };
  }, [authUserResponse, dispatch]);
  return (
    <>
      {isUserAuthorized && children}
      {!isUserAuthorized && <NoAccess />}
    </>
  );
};

export default AuthorizedAccess;
