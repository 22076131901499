export const FILE_NAME = 'Demand Forecast (Excel Spreadsheet)';
export const UPLOAD_BOX_TEXT = 'Upload or Drop Excel file here';
export const BROWSE_FILE = 'Browse File';
export const SUBMIT = 'Submit';
export const SYNC_BTN = 'Sync';
export const MODAL_ERR_MSG = 'Sync Failed';
export const SUCCESS = 'success';
export const EDIT_FILE = 'Edit File';
export const BUFFER_SPREADSHEET = 'Buffer Spreadsheet (Google Spreadsheet)';
export const DAILY = 'daily';
export const FAILURE = 'Failure';
export const ERROR_MESSAGE = 'Sorry, Something Went Wrong!';
export const SHEET_UNAVAILABLE = 'Sorry, Sheet unavailable!';
export const SUCCESS_HEADING = 'Submitted successfully';
export const SYNC_UPLOAD_MSG = 'has been submitted successfully';
export const FAILED = 'failed';
export const INTERHUB_DISPATCH_PLAN_UPLOAD =
  'Interhub dispatch plan upload (Excel Spreadsheet)';
export const FILE_NOT_UPLOADED = 'File Upload Failed';
