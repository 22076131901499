/* Primary React Imports(V) */
import React, { useEffect, useState } from 'react';
/* Redux Dependencies(M) */
import { useSelector } from 'react-redux';
/* MUI Component Imports */
// import { Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SSO_LOGIN_PATH } from '../../utils/serviceUrls';

/* Typography and Colors */
import {
  HEADINGTYPOGRAPHY,
  PARAGRAPHTYPOGRAPHY,
} from '../../common/typography';

/* Base Styled Components */
import {
  Heading2,
  // Heading3,
  MainLoginPageContainer,
  Paragraph,
} from '../../common/styles/base-styled-components';

/* Styles */
import {
  LoginContainer,
  LoginFormContainer,
  LoginHeader,
  LoginHeaderTextWrapper,
} from './styles';

/* Assets */
import LiciousLogo from '../../assets/licious_logo.png';
import Icon from '../../components/Icon';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/* Functional Component */
const Login = (props) => {
  /* Redux Props */
  const { isAuthLoading, authUserError } = useSelector(
    (state) => state.authReducer,
  );
  /* Local State Managemnet */
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  useEffect(() => {
    if (authUserError && authUserError.response) setIsSnackBarOpen(true);
  }, [authUserError]);

  const listener = (event) => {
    const { code } = event;
    if (code === 'Enter' || code === 'NumpadEnter') {
      handleRedirect();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Event Handlers */

  const handleRedirect = () => {
    window.location.assign(SSO_LOGIN_PATH);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  /* MUI Custom Components */
  const LogInButton = styled(LoadingButton)({
    marginTop: '10px',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #DEE2E6',
    padding: '15px',
    color: '#fff ',
    textTransform: 'none',
    fontFamily: 'Lato',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: '#C5003E',
    },
  });

  /* JSX */
  return (
    <MainLoginPageContainer>
      <LoginContainer onSubmit={handleSubmit}>
        <LoginHeader>
          <LoginHeaderTextWrapper>
            <Heading2
              color={'#000'}
              size={HEADINGTYPOGRAPHY['heading-2'].size}
              weight={HEADINGTYPOGRAPHY['heading-2'].weight}>
              Sign In
            </Heading2>
            <Paragraph
              color={'#000'}
              size={PARAGRAPHTYPOGRAPHY['paragraph-regular'].size}
              weight={PARAGRAPHTYPOGRAPHY['paragraph-regular'].weight}>
              Use your Licious Account
            </Paragraph>
          </LoginHeaderTextWrapper>
          <img src={LiciousLogo} alt="Licious Logo" />
        </LoginHeader>
        <LoginFormContainer>
          <LogInButton
            type="submit"
            variant="contained"
            loading={isAuthLoading}
            endIcon={<Icon name="login_right_arrow" width="20" height="10" />}
            loadingPosition="end"
            sx={{ backgroundColor: '#C5003E' }}
            onClick={handleRedirect}
            disableElevation
            disableFocusRipple
            disableTouchRipple
            disableRipple
            data-testid="OAUTH_LOGIN_BUTTON">
            {isAuthLoading ? 'Logging in..' : 'Login with Google'}
          </LogInButton>
        </LoginFormContainer>
      </LoginContainer>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        {authUserError && authUserError.response && (
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {authUserError && authUserError.response}
          </Alert>
        )}
      </Snackbar>
    </MainLoginPageContainer>
  );
};

export default Login;
