import { useSelector } from 'react-redux';
import { selectUserRoles } from '../../redux/UserReducer/selector';

function RBACGuard({ allowedRoles, children }) {
  const userRoles = useSelector(selectUserRoles);
  const allowedAccess =
    userRoles.filter((userRole) => allowedRoles.includes(userRole)).length > 0;
  return <>{allowedAccess && children}</>;
}

export default RBACGuard;
