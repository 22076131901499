import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PPButton from '../../../common/PPButton';
import PPUploadComponent from '../../../common/PPUploadComponent';
import { EDIT_FILE } from '../constants';
import {
  ERROR_MESSAGE,
  MODAL_ERR_MSG,
  SHEET_UNAVAILABLE,
  SUCCESS,
  SUCCESS_HEADING,
  SYNC_UPLOAD_MSG,
} from '../DailyComponent/constants';
import {
  SYNC_LABEL,
  MASTER,
  ATTENTION_MODAL_HEADING,
  ATTENTION_MODAL_SUBMSG,
} from './constants';
import styles from './styles.module.scss';
import { getGoogleSheetUrl } from '../../../utils/serviceUrls';
import { formatDate, masterFileSync } from '../DailyComponent/utils';
import StorageService from '../../../utils/storageService';
import { USER_TOKEN_STORAGE_KEY } from '../../LoginProvider/constants';
import PPModal from '../../../common/PPModal';
import PPAttentionModal from '../../../common/PPAttentionModal';
import { UPDATE_MASTER_PLAN_INPUTS } from '../../../redux/ProductionPlanning/constants';
import Icon from '../../../components/Icon';

function MasterComponent() {
  const [open, setModalOpen] = useState(false);
  const [setSync, syncBtnStatus] = useState(true);
  const [dynamicSheetErr, setDynamicSheetErr] = useState('');
  const [activeFile, setActiveFile] = useState('');
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [subMsg, setSubMsg] = useState('');
  const [succArrList, setSuccArrList] = useState([]);
  const [fileCount, setFileCount] = useState();
  const [attentionModal, setAttentionModal] = useState(false);
  const [fullSuccErr, setFullSuccErr] = useState(false);
  const [fileCountForAttentionModal, setFileCountForAttentionModal] =
    useState();
  const [activeFileForAttentionModal, setActiveFileForAttentionModal] =
    useState();

  const { authUserResponse } = useSelector((state) => state?.authReducer);
  const token = StorageService.getValue(USER_TOKEN_STORAGE_KEY);
  const name = authUserResponse?.email_id;
  const editedFileArr = useRef([]);
  const {
    productionPlanInputsData,
    productionPlanInputsLoading,
    productionPlanInputsError,
  } = useSelector((rootReducer) => rootReducer?.productionPlanningReducer);
  const { inputMetadata } = productionPlanInputsData;

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
    setIsSubmitSuccess(false);
    setSubMsg('');
    setActiveFile('');
    setFileCount(0);
  };

  const handleAttentionModalClose = () => {
    setAttentionModal(false);
    setFileCountForAttentionModal(0);
    setActiveFileForAttentionModal('');
  };
  const dispatch = useDispatch();

  function handleEditFileOpen(sheetId, fileName, id) {
    setActiveFile(fileName);
    syncBtnStatus(false);
    if (sheetId) {
      window.open(getGoogleSheetUrl(sheetId), '_blank');
    } else {
      setDynamicSheetErr(SHEET_UNAVAILABLE);
    }
    const item =
      editedFileArr?.current &&
      editedFileArr?.current?.some(
        (each) => each?.toString() === id?.toString(),
      );
    if (item) {
      return;
    } else {
      editedFileArr?.current?.push(id);
    }
  }

  function handleSyncClick(id) {
    let uploaded_by = name;
    let arrLength = editedFileArr.current.length - 1;
    let payload = getMasterInputMetaData().filter((item) =>
      editedFileArr.current.includes(item.id),
    );

    setSyncInProgress(true);
    syncBtnStatus(true);
    masterFileSync(token, uploaded_by, {
      inputIds: editedFileArr.current,
      payload: payload,
    })
      .then((res) => {
        if (res?.status === SUCCESS) {
          syncBtnStatus(true);
          setSyncInProgress(false);
          setSuccArrList(res?.data);
          setActiveFile(id);
          setFileCount(arrLength);
          editedFileArr.current = [];

          if (res?.data) {
            const hasSuccess = res?.data.some(
              (each) => each.status === SUCCESS,
            );
            const hasError = res?.data.some((each) => each.status !== SUCCESS);

            const failedFilesIds = res?.data
              .filter((input) => input?.status !== SUCCESS)
              .map((input) => input.inputId);

            const failedFiles = getMasterInputMetaData().filter((input) =>
              failedFilesIds.includes(input.id),
            );

            const failedFilesLength = failedFiles.length - 1;

            // update timestamp of success files
            res?.data.forEach((input) => {
              if (input?.status?.toLowerCase() === SUCCESS) {
                dispatch({
                  type: UPDATE_MASTER_PLAN_INPUTS,
                  payload: {
                    id: input.inputId,
                    lastUpdatedAt: input.updatedAt,
                  },
                });
              }
            });

            if (hasSuccess && hasError) {
              setFileCountForAttentionModal(failedFilesLength);
              setActiveFileForAttentionModal(failedFiles?.[0]?.title);
              setFullSuccErr(false);
              setAttentionModal(true); // show attention
            } else if (hasSuccess && !hasError) {
              setModalOpen(true);
              setSubMsg('');
              setIsSubmitSuccess(true);
              setFullSuccErr(true); // show success
              setTimeout(() => {
                setModalOpen(false);
              }, 3000);
            } else if (!hasSuccess && hasError) {
              setModalOpen(true);
              setSubMsg(res?.message);
              setIsSubmitSuccess(false);
              setFullSuccErr(true); // show error
            }
          }
        } else {
          setModalOpen(true);
          setFullSuccErr(true);
          setSubMsg('');
          setSyncInProgress(false);
          syncBtnStatus(false);
        }
      })
      .catch((error) => {
        setModalOpen(true);
        setSubMsg(error?.message);
        setSyncInProgress(false);
        setDynamicSheetErr(error?.message);
        setIsSubmitSuccess(false);
      });
  }

  function getMasterInputMetaData() {
    return inputMetadata?.filter((data) => data?.type === MASTER) || [];
  }

  function getLastUpdatedAt(dateObj) {
    return formatDate(dateObj);
  }

  function getSheetErrorMessage(sheetId) {
    if (dynamicSheetErr) return dynamicSheetErr;
    const message =
      succArrList.find((input) => input.inputId === sheetId)?.message || '';
    return message;
  }

  function shouldturnBluecolor(sheetId) {
    const status = succArrList.find(
      (input) => input.inputId === sheetId,
    )?.status;
    return status === SUCCESS;
  }

  function getDynamicSuccessMsg(sheetId) {
    return (
      succArrList.find((input) => input.inputId === sheetId)?.message || ''
    );
  }

  return (
    <div data-testid="master_tab_content" className={styles.main_container}>
      {productionPlanInputsData?.status === SUCCESS && (
        <>
          {getMasterInputMetaData()?.map((data) => (
            <PPUploadComponent
              data-testid="master_input"
              key={data?.id}
              title={data?.title}
              uploadBoxTitle={data?.sheetName}
              firstBtnLabel={EDIT_FILE}
              firstIcon={<Icon name="edit_file_icon" width="20" height="22" />}
              handleEditFileOpen={() =>
                handleEditFileOpen(data?.sheetId, data?.title, data?.id)
              }
              uploadFile={false}
              updatedAt={getLastUpdatedAt(data?.lastUpdatedAt)}
              // activeFile={activeFile}
              dynamicSheetErrorMsg={getSheetErrorMessage(data?.id)}
              dynamicSheetSuccessMsg={getDynamicSuccessMsg(data?.id)}
              turnBluecolor={shouldturnBluecolor(data?.id)}
            />
          ))}
        </>
      )}

      {(productionPlanInputsError ||
        productionPlanInputsData.status !== SUCCESS) &&
        !productionPlanInputsLoading && (
          <div className={styles.main_err_div}>{ERROR_MESSAGE}</div>
        )}

      {/* Submit Action Button */}
      {productionPlanInputsData?.status === SUCCESS && (
        <div className={styles.master_sync_btn}>
          <PPButton
            data-testid="master_input_sync_btn"
            variant="contained"
            classes={{
              root: setSync ? styles.disabled_button : styles.enabled_button,
            }}
            startIcon={<Icon name="sync_icon" width="21" height="20" />}
            onClick={() => handleSyncClick(activeFile)}
            isAnimating={syncInProgress}
            disabled={setSync}>
            <span className={styles.sync_txt}>{SYNC_LABEL}</span>
          </PPButton>
        </div>
      )}
      {fullSuccErr && (
        <div>
          <PPModal
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            icon={
              isSubmitSuccess ? (
                <Icon name="green_tick" height={80} width={80} />
              ) : (
                <Icon name="warning" height={76} width={78} />
              )
            }
            modalHeading={isSubmitSuccess ? SUCCESS_HEADING : MODAL_ERR_MSG}
            subMsg={subMsg}
            success={isSubmitSuccess}
            activeFileSubMsg={
              activeFile
                ? activeFile +
                  (fileCount > 0 ? '...(+' + fileCount + ') ' : ' ') +
                  SYNC_UPLOAD_MSG
                : ''
            }
          />
        </div>
      )}

      <div>
        <PPAttentionModal
          open={attentionModal}
          handleClose={handleAttentionModalClose}
          icon={<Icon name="attentionIcon" height={76} width={78} />}
          modalHeading={ATTENTION_MODAL_HEADING}
          subMsg={ATTENTION_MODAL_SUBMSG}
          activeFile={activeFileForAttentionModal}
          fileCount={fileCountForAttentionModal}
        />
      </div>
    </div>
  );
}

export default MasterComponent;
