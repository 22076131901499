import { useNavigate, useParams } from 'react-router-dom';
import { selectConfigData } from '../../redux/ConfigManagement/selectors';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  editConfig,
  getConfig,
} from '../../redux/saga/ConfigManagement/actions';
import PPButton from '../../common/PPButton';
import style from './style.module.scss';
import EditInput from './EditInput';
import EditConfigMetadata from './EditConfigMetadata';
import { ADD_CONFIG_SUBMIT, CANCEL, EDIT_CONFIG } from './constants';
import cx from 'classnames';
import { CONFIG_LIST_PATH } from '../../Routing/paths';
import Loader from '../../common/Loader';

const EditConfig = () => {
  const { property } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, selectedConfig } = useSelector(selectConfigData);

  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (!Object.keys(formData).length) {
      dispatch(getConfig({ id: property }));
    }
  }, [dispatch, formData, property, selectedConfig]);

  useEffect(() => {
    setFormData({ ...selectedConfig });
  }, [selectedConfig]);

  const onformdataChange = (event) => {
    setFormData({ ...formData, value: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editConfig({ ...formData }));
  };

  const handleCancelClick = () => {
    navigate(CONFIG_LIST_PATH);
  };

  if (error) {
    return <div>{error?.error}</div>;
  }

  return (
    <Loader showLoader={loading}>
      <div className={style.edit_config_container}>
        <h2 className={style.title}>{EDIT_CONFIG}</h2>
        <div className={style.edit_config}>
          <form className={style.edit_config_form} onSubmit={handleSubmit}>
            <EditInput
              multiline
              disabled={true}
              label={'Key'}
              value={formData?.key ?? ' '}
            />
            <EditInput
              multiline
              label={'Value'}
              value={formData?.value ?? ' '}
              onChange={onformdataChange}
            />
            <div className={style.action_btn_grp}>
              <PPButton
                type="button"
                classes={{ root: cx(style.btn, style.cancel_btn) }}
                onClick={handleCancelClick}>
                {CANCEL}
              </PPButton>
              <PPButton
                type="submit"
                classes={{ root: cx(style.btn, style.submit_btn) }}
                disabled={!formData?.value}>
                {ADD_CONFIG_SUBMIT}
              </PPButton>
            </div>
          </form>
          <EditConfigMetadata data={formData} />
        </div>
      </div>
    </Loader>
  );
};

export default EditConfig;
