export const HEADINGTYPOGRAPHY = {
  'heading-1': { size: 18, weight: 700 },
  'heading-1-sb': { size: 17, weight: 600 },
  'heading-2': { size: 24, weight: 700 },
  'heading-3': { size: 32, weight: 900 },
};

export const PARAGRAPHTYPOGRAPHY = {
  'paragraph-small': { size: 10, weight: 300 },
  'paragraph-regular': { size: 14, weight: 400 },
  'paragraph-regular-bold': { size: 14, weight: 500 },
};

export const TITLETYPOGRAPHY = {
  title: { size: 16, weight: 300 },
  'title-2': { size: 16, weight: 400 },
  'title-selected': { size: 16, weight: 400 },
};
