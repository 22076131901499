import { SET_ACTIVE_TAB } from './constants';
import { createReducer } from '../../utils/createReducer';

const INITIAL_STATE = {
  activeTab: 0,
};
const headerReducer = {
  [SET_ACTIVE_TAB]: (state, action) => ({
    ...state,
    activeTab: action.payload,
  }),
};

export default createReducer(INITIAL_STATE, headerReducer);
