import PPSideBar from '../../common/PPSideBar';
import styles from './styles.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRBACTabs } from './useRBACTabs';

const ProductionPlanning = () => {
  const [tabs, activeTab, setActiveTab] = useRBACTabs();
  const navigate = useNavigate();

  function handleTabClick(_, newValue) {
    setActiveTab(newValue);
    navigate(newValue);
  }

  return (
    <div className={styles.main_container}>
      <PPSideBar
        data-testid="production_planning_sidebar"
        tabs={tabs}
        className={styles.sidebar_container}
        activeTab={activeTab}
        onChange={handleTabClick}
      />
      <Outlet />
    </div>
  );
};

export default ProductionPlanning;
