import { EDIT_CONFIG_METADATA_PROPERTIES } from './constants';
import style from './style.module.scss';

const EditConfigMetadata = ({ data = {} }) => {
  return (
    <div className={style.edit_config_metadata}>
      {EDIT_CONFIG_METADATA_PROPERTIES.map((property) => (
        <div key={property.label} className={style.config_property}>
          <span className={style.metadata_label}>{property.label}</span>
          <span>{data?.[property.property]}</span>
        </div>
      ))}
    </div>
  );
};

export default EditConfigMetadata;
