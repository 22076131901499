export const CONFIG_TABLE_COLUMN_CONFIG = [
  { id: 'key', title: 'Property', key: 'key', width: '300px' },
  { id: 'value', title: 'Value', key: 'value', width: '200px' },
  { id: 'created_at', title: 'Created at', key: 'createdAt', width: '250px' },
  { id: 'created_by', title: 'Created by', key: 'createdBy', width: '100px' },
  { id: 'updated_at', title: 'Updated at', key: 'updatedAt', width: '250px' },
  { id: 'updated_by', title: 'Updated by', key: 'updatedBy', width: '100px' },
  { id: 'edit', title: 'Action', key: 'edit', width: '15px' },
];

export const EDIT_CONFIG_METADATA_PROPERTIES = [
  { property: 'createdAt', label: 'Created at' },
  { property: 'createdBy', label: 'Created by' },
  { property: 'updatedAt', label: 'Updated at' },
  { property: 'updatedBy', label: 'Updated by' },
];

export const ADD_CONFIG = 'Add config';
export const ADD_CONFIG_SUBMIT = 'Submit';
export const EDIT_CONFIG = 'Edit config';
export const CANCEL = 'Cancel';
