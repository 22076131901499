import { all, fork } from 'redux-saga/effects';

import UserReducerSaga from './UserReducer';
import ProductionPlanningSaga from './ProductionPlanning';
import ConfigManagementSaga from './ConfigManagement';

export default function* rootSaga() {
  yield all([
    fork(UserReducerSaga),
    fork(ProductionPlanningSaga),
    fork(ConfigManagementSaga),
  ]);
}
