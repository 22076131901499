import React, { Fragment } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  background-color: transparent;
`;

const LoadingIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid #868e96;
  border-top: 3px solid #c92a2a;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const Loader = ({ showLoader, children }) => {
  return (
    <Fragment>
      {showLoader ? (
        <Wrapper>
          <LoadingIcon />
        </Wrapper>
      ) : (
        children
      )}
    </Fragment>
  );
};
export default Loader;
