import { Box, Tab, Tabs } from '@mui/material';
import styles from './styles.module.scss';

function LsTab({ value, onChange, list, disabled }) {
  const handleChangeTab = (evt, newVal) => {
    onChange(newVal);
  };
  if (!value) return null;
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        className={styles.ls_tabs}
        value={value}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="ls-tabs"
        classes={{ indicator: styles.active_tab_indicator }}>
        {list.map((each) => (
          <Tab
            disabled={disabled}
            key={each.value}
            className={styles.ls_tab}
            label={each.label}
            value={each.value}
            classes={{ selected: styles.tab_txt_color, root: styles.ls_tab }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
export default LsTab;
