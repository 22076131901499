import { format } from 'date-fns';
import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { DEFAULT_ERROR_MESSAGE, PRODUCTION_PLAN } from '../../../constants';
import { apiCall } from '../../../utils/axiosAPIWrapper';
import {
  getGoogleSheetUrl,
  INVENTORY_UPDATES_URL,
  PRODUCTION_PLAN_INPUTS_URL,
  PRODUCTION_PLAN_TAB_METADATA,
} from '../../../utils/serviceUrls';
import {
  GET_PRODUCTION_PLAN_INPUTS_ERROR,
  GET_PRODUCTION_PLAN_INPUTS_SUCCESS,
} from '../../ProductionPlanning/constants';
import {
  GET_INVENTORY_STATUS_DATA,
  GET_INVENTORY_STATUS_DATA_COMPLETE,
  GET_PRODUCTION_PLAN_TAB_METADATA,
  GET_PRODUCTION_PLAN_TAB_METADATA_SUCCESS,
} from '../../ProductionPlanning/ProductionPlan/constants';
import { GET_PRODUCTION_PLAN_INPUTS } from './constants';

function* getProductionPlanInputs() {
  const { authUserResponse } = yield select((state) => state.authReducer);
  const user = authUserResponse?.email_id;
  try {
    const response = yield call(
      apiCall,
      'GET',
      PRODUCTION_PLAN_INPUTS_URL,
      null,
      {
        'X-REQUESTED-APP': PRODUCTION_PLAN,
        'X-REQUESTED-BY': user,
      },
    );
    yield put({
      type: GET_PRODUCTION_PLAN_INPUTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: GET_PRODUCTION_PLAN_INPUTS_ERROR,
      payload: 'Something went wrong',
    });
  }
}

function getTimestampDetails(dateValue) {
  if (!dateValue) return null;
  const date = new Date(dateValue);
  return {
    date: format(date, 'd-MMM-yyyy'),
    time: format(date, 'hh:mm a'),
  };
}

function* getProductionPlanTabMetadata() {
  try {
    const { authUserResponse } = yield select((state) => state.authReducer);
    const user = authUserResponse?.email_id;
    const response = yield call(
      apiCall,
      'GET',
      PRODUCTION_PLAN_TAB_METADATA,
      null,
      {
        'X-REQUESTED-APP': PRODUCTION_PLAN,
        'X-REQUESTED-BY': user,
      },
    );
    const { metadata } = response || {};
    let list = [];
    if (Array.isArray(metadata)) {
      list = metadata.map((each) => ({
        ...each,
        label: each.pcName,
        value: each.id,
        sheetUrl: getGoogleSheetUrl(each.sheetId),
        planGenerated: getTimestampDetails(each.planGeneratedAt),
        planUpdated: getTimestampDetails(each.planUpdatedAt),
        inventoryUpdated: getTimestampDetails(each.inventoryUpdatedAt),
      }));
    }
    yield put({
      type: GET_PRODUCTION_PLAN_TAB_METADATA_SUCCESS,
      payload: {
        data: { list },
        error: false,
      },
    });
  } catch (e) {
    const { data } = e || {};
    const { message, error } = data || {};
    yield put({
      type: GET_PRODUCTION_PLAN_TAB_METADATA_SUCCESS,
      payload: {
        error: message || error || DEFAULT_ERROR_MESSAGE,
      },
    });
  }
}

function* getInventoryStatusData({ payload }) {
  const controller = new AbortController();
  try {
    const { pcId, productionPlanId } = payload;
    const { authUserResponse } = yield select((state) => state.authReducer);
    const user = authUserResponse?.email_id;
    // add cancel token here to cancel previous requests
    const inventoryStatusDataResponse = yield call(
      apiCall,
      'GET',
      INVENTORY_UPDATES_URL,
      null,
      {
        'X-REQUESTED-APP': PRODUCTION_PLAN,
        'X-REQUESTED-BY': user,
      },
      { productionPlanId, pcId },
      controller.signal,
    );

    const inventoryStatusData = inventoryStatusDataResponse?.data?.map(
      (inv) => {
        const categoryConfirmations = inv?.categoryConfirmations?.map(
          (cat) => ({
            category: cat?.category,
            skuUpdate: `${
              cat?.confirmedQuantity !== null
                ? String(cat?.confirmedQuantity).padStart(2, '0')
                : '-'
            } / ${
              cat?.totalQuantity !== null
                ? String(cat?.totalQuantity).padStart(2, '0')
                : '-'
            }`,
            status: cat?.status,
            ...cat,
          }),
        );
        return { ...inv, categoryConfirmations };
      },
    );

    yield put({
      type: GET_INVENTORY_STATUS_DATA_COMPLETE,
      payload: {
        inventoryStatusError: '',
        inventoryStatusData,
      },
    });
  } catch (error) {
    yield put({
      type: GET_INVENTORY_STATUS_DATA_COMPLETE,
      payload: {
        inventoryStatusError:
          error?.data?.errors?.[0].message || DEFAULT_ERROR_MESSAGE,
        inventoryStatusData: [],
      },
    });
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
  }
}

export default function* watcher() {
  yield takeLatest(GET_PRODUCTION_PLAN_INPUTS, getProductionPlanInputs);
  yield takeLatest(
    GET_PRODUCTION_PLAN_TAB_METADATA,
    getProductionPlanTabMetadata,
  );
  yield takeLatest(GET_INVENTORY_STATUS_DATA, getInventoryStatusData);
}
