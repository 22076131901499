import styled from 'styled-components';

export const LoginContainer = styled.form`
  padding: 40px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 500px;
`;

export const LoginHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoginHeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .MuiFormHelperText-root {
    font-size: 14px;
  }
`;
