import { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PPButton from '../../../common/PPButton';
import PPModal from '../../../common/PPModal';
import PPUploadComponent from '../../../common/PPUploadComponent';
import Icon from '../../../components/Icon';
import { PRODUCTION_PLAN } from '../../../constants';
import {
  DP_FUTURES_SYNCS,
  getGoogleSheetUrl,
} from '../../../utils/serviceUrls';
import StorageService from '../../../utils/storageService';
import { USER_TOKEN_STORAGE_KEY } from '../../LoginProvider/constants';
import { EDIT_FILE } from '../constants';
import {
  ERROR_MESSAGE,
  MODAL_ERR_MSG,
  SHEET_UNAVAILABLE,
  SUCCESS,
  SUCCESS_HEADING,
  SYNC_BTN,
  SYNC_UPLOAD_MSG,
} from '../DailyComponent/constants';
import { DPformatDate } from '../DailyComponent/utils';
import styles from './styles.module.scss';
import { getProductionPlanInputs } from '../../../redux/saga/ProductionPlanning/actions';
import { planningMasterSync } from './helper';
import {
  ATTENTION_MODAL_HEADING,
  ATTENTION_MODAL_SUBMSG,
} from '../MasterComponent/constants';
import PPAttentionModal from '../../../common/PPAttentionModal';
import { UPDATE_MASTER_PLAN_INPUTS } from '../../../redux/ProductionPlanning/constants';

const OccassionallyTab = () => {
  const dispatch = useDispatch();
  const [activeFile, setActiveFile] = useState('');
  const [modalHeading, setModalHeading] = useState('');
  const [syncButtonDisabled, setSyncButtonDisabled] = useState({});
  const [syncInProgress, setSyncInProgress] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(null);
  const [subMsg, setSubMsg] = useState('');
  const [fileCountForAttentionModal, setFileCountForAttentionModal] =
    useState();
  const [activeFileForAttentionModal, setActiveFileForAttentionModal] =
    useState();
  const [succArrList, setSuccArrList] = useState([]);
  const [fileCount, setFileCount] = useState();
  const [attentionModal, setAttentionModal] = useState(false);
  const [fullSuccErr, setFullSuccErr] = useState(false);
  const [dynamicSheetErr, setDynamicSheetErr] = useState('');
  const token = StorageService.getValue(USER_TOKEN_STORAGE_KEY);
  const {
    productionPlanInputsData,
    productionPlanInputsLoading,
    productionPlanInputsError,
  } = useSelector((rootReducer) => rootReducer.productionPlanningReducer);
  const { inputMetadata } = productionPlanInputsData;
  const { authUserResponse } = useSelector((state) => state?.authReducer);
  const name = authUserResponse?.email_id;
  const occassionallyUpdatedSheets = inputMetadata?.filter(
    (sheet) => sheet.type === 'occasionally',
  );
  const editedDPFutureFileArr = useRef([]);
  const editedOccassionallyFileArr = useRef([]);

  function handleEditFileOpen(sheetId, fileName, id) {
    setActiveFile(fileName);
    setSuccArrList([]);
    setSyncButtonDisabled({ ...syncButtonDisabled, [id]: false });
    if (sheetId) {
      window.open(getGoogleSheetUrl(sheetId), '_blank');
    } else {
      setDynamicSheetErr(SHEET_UNAVAILABLE);
    }
    if (fileName === 'dp_futures') {
      const item =
        editedDPFutureFileArr?.current &&
        editedDPFutureFileArr?.current?.some(
          (each) => each?.toString() === id?.toString(),
        );
      if (!item) {
        editedDPFutureFileArr?.current?.push(id);
      }
    } else {
      const item =
        editedOccassionallyFileArr?.current &&
        editedOccassionallyFileArr?.current?.some(
          (each) => each?.toString() === id?.toString(),
        );
      if (!item) {
        editedOccassionallyFileArr?.current?.push(id);
      }
    }
  }

  async function syncEditedFile(_fileName, title, id) {
    if (title === 'dp_futures') {
      await handleDPFuturesSync(id);
    } else if (title === 'replenishment_features') {
      await handleClusterThresholdSync(id);
    }
  }

  async function handleDPFuturesSync(id) {
    setSyncInProgress({ ...syncInProgress, [id]: true });
    setIsSubmitSuccess(false);
    setModalOpen(false);
    try {
      const res = await fetch(DP_FUTURES_SYNCS, {
        headers: {
          token: token,
          'X-REQUESTED-APP': PRODUCTION_PLAN,
          'X-REQUESTED-BY': name,
          'content-type': 'application/json; charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
        method: 'POST',
        body: JSON.stringify({ inputIds: editedDPFutureFileArr.current }),
      });
      if (res.ok) {
        const data = await res.json();
        setModalHeading(SUCCESS_HEADING);
        setSubMsg(data?.message);
        setIsSubmitSuccess(true);
        setModalOpen(true);
        dispatch(getProductionPlanInputs());
        setTimeout(() => {
          handleClose();
        }, 3000);
      } else {
        setModalHeading(MODAL_ERR_MSG);
        setSubMsg('');
        setIsSubmitSuccess(false);
        setModalOpen(true);
      }
    } catch (e) {
      setSubMsg(e?.msg);
      setModalHeading(MODAL_ERR_MSG);
      setIsSubmitSuccess(false);
      setModalOpen(true);
    } finally {
      setSyncInProgress({ ...syncInProgress, [id]: false });
      setSyncButtonDisabled({ ...syncButtonDisabled, [id]: true });
    }
  }
  async function handleClusterThresholdSync(id) {
    setSyncInProgress({ ...syncInProgress, [id]: true });
    planningMasterSync({
      token,
      name,
      data: {
        inputIds: editedOccassionallyFileArr.current,
      },
    })
      .then((res) => {
        if (res?.status === SUCCESS) {
          setSyncInProgress({ ...syncInProgress, [id]: false });
          setSuccArrList(res?.data);
          setFileCount(editedOccassionallyFileArr.current.length - 1);
          editedOccassionallyFileArr.current = [];

          if (res?.data) {
            const hasSuccess = res?.data.some(
              (each) => each.status === SUCCESS,
            );
            const failedFilesIds = res?.data
              ?.filter((input) => input?.status !== SUCCESS)
              .map((input) => input.inputId);
            const hasError = failedFilesIds.length > 0;

            const failedFiles = occassionallyUpdatedSheets.filter((input) =>
              failedFilesIds.includes(input.id),
            );
            // update timestamp of success files
            res?.data.forEach((input) => {
              if (input?.status?.toLowerCase() === SUCCESS) {
                dispatch({
                  type: UPDATE_MASTER_PLAN_INPUTS,
                  payload: {
                    id: input.inputId,
                    lastUpdatedAt: input.updatedAt,
                  },
                });
              }
            });
            if (hasSuccess && hasError) {
              setFileCountForAttentionModal(failedFiles.length - 1);
              setActiveFileForAttentionModal(failedFiles?.[0]?.title);
              setFullSuccErr(false);
              setAttentionModal(true); // show attention
            } else if (hasSuccess && !hasError) {
              setModalOpen(true);
              setSubMsg('');
              setIsSubmitSuccess(true);
              setFullSuccErr(true); // show success
              setTimeout(() => {
                setModalOpen(false);
              }, 3000);
            } else if (!hasSuccess && hasError) {
              setModalOpen(true);
              setSubMsg(res?.message);
              setIsSubmitSuccess(false);
              setFullSuccErr(true); // show error
              setSyncInProgress({ ...syncInProgress, [id]: false });
            }
          } else {
            setModalOpen(true);
            setFullSuccErr(true);
            setSubMsg('');
            setSyncInProgress({ ...syncInProgress, [id]: false });
          }
        }
      })
      .catch((error) => {
        setModalOpen(true);
        setSubMsg(error?.message);
        setSyncInProgress({ ...syncInProgress, [id]: false });
        setDynamicSheetErr(error?.message);
        setIsSubmitSuccess(false);
      })
      .finally(() => {
        setSyncButtonDisabled({ ...syncButtonDisabled, [id]: true });
      });
  }

  const handleClose = () => {
    setModalOpen(false);
    setFullSuccErr(false);
    setModalHeading('');
    setIsSubmitSuccess(false);
    setSubMsg('');
    setIsSubmitSuccess(false);
    setSubMsg('');
    setActiveFile('');
    setFileCount(0);
  };

  function getSheetErrorMessage(sheetId) {
    if (dynamicSheetErr) return dynamicSheetErr;
    const message =
      succArrList.find((input) => input.inputId === sheetId)?.message || '';
    return message;
  }

  function shouldturnBluecolor(sheetId) {
    const status = succArrList.find(
      (input) => input.inputId === sheetId,
    )?.status;
    return status === SUCCESS;
  }

  function getDynamicSuccessMsg(sheetId) {
    return (
      succArrList.find((input) => input.inputId === sheetId)?.message || ''
    );
  }

  const handleOpen = () => setModalOpen(true);

  const handleAttentionModalClose = () => {
    setAttentionModal(false);
    setFileCountForAttentionModal(0);
    setActiveFileForAttentionModal('');
  };

  return (
    <div className={styles.main_container}>
      {productionPlanInputsData?.status === SUCCESS && (
        <Fragment>
          {occassionallyUpdatedSheets.map((sheet) => (
            <Fragment key={sheet.id}>
              <PPUploadComponent
                data-testid="daily_input_metadata"
                title={sheet?.title?.split('_').join(' ') ?? 'N.A.'}
                uploadBoxTitle={sheet?.sheetName}
                firstBtnLabel={EDIT_FILE}
                updatedAt={DPformatDate(sheet?.lastUpdatedAt)}
                showSubmit={true}
                firstIcon={
                  <Icon name="edit_file_icon" width="22" height="22" />
                }
                handleEditFileOpen={() =>
                  handleEditFileOpen(sheet.sheetId, sheet?.title, sheet?.id)
                }
                dynamicSheetErrorMsg={getSheetErrorMessage(sheet?.id)}
                dynamicSheetSuccessMsg={getDynamicSuccessMsg(sheet?.id)}
                turnBluecolor={shouldturnBluecolor(sheet?.id)}
              />
              <div className={styles.submit_div}>
                <PPButton
                  data-testid="daily_input_sync_btn"
                  variant="contained"
                  classes={{
                    root:
                      syncButtonDisabled?.[sheet.id] ?? true
                        ? styles.disabled_button
                        : styles.enabled_button,
                  }}
                  startIcon={<Icon name="sync_icon" width="21" height="20" />}
                  onClick={() =>
                    syncEditedFile(activeFile, sheet.title, sheet.id)
                  }
                  isAnimating={syncInProgress?.[sheet?.id]}
                  disabled={syncButtonDisabled?.[sheet.id] ?? true}>
                  <span className={styles.sync_txt}>{SYNC_BTN}</span>
                </PPButton>
              </div>
            </Fragment>
          ))}

          {modalOpen && (
            <PPModal
              data-testid="full_succ_error"
              open={modalOpen}
              handleClose={handleClose}
              icon={
                isSubmitSuccess ? (
                  <Icon name="green_tick" height={80} width={80} />
                ) : (
                  <Icon name="warning" height={76} width={78} />
                )
              }
              modalHeading={modalHeading}
              success={isSubmitSuccess}
              subMsg={subMsg}
            />
          )}
        </Fragment>
      )}
      {(productionPlanInputsError ||
        (productionPlanInputsData?.status !== SUCCESS &&
          !productionPlanInputsLoading)) && (
        <div className={styles.main_err_div}>{ERROR_MESSAGE}</div>
      )}
      {fullSuccErr && (
        <div>
          <PPModal
            open={modalOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            icon={
              isSubmitSuccess ? (
                <Icon name="green_tick" height={80} width={80} />
              ) : (
                <Icon name="warning" height={76} width={78} />
              )
            }
            modalHeading={isSubmitSuccess ? SUCCESS_HEADING : MODAL_ERR_MSG}
            subMsg={subMsg}
            success={isSubmitSuccess}
            activeFileSubMsg={
              activeFile
                ? activeFile +
                  (fileCount > 0 ? '...(+' + fileCount + ') ' : ' ') +
                  SYNC_UPLOAD_MSG
                : ''
            }
          />
        </div>
      )}
      {attentionModal && (
        <PPAttentionModal
          open={attentionModal}
          handleClose={handleAttentionModalClose}
          icon={<Icon name="attentionIcon" height={76} width={78} />}
          modalHeading={ATTENTION_MODAL_HEADING}
          subMsg={ATTENTION_MODAL_SUBMSG}
          activeFile={activeFileForAttentionModal}
          fileCount={fileCountForAttentionModal}
        />
      )}
    </div>
  );
};
export default OccassionallyTab;
