import TableRow from './TableRow';

const TableHeader = ({ children }) => {
  return (
    <thead>
      <TableRow>{children}</TableRow>
    </thead>
  );
};

export default TableHeader;
