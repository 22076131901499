import { EDIT_CONFIG, GET_CONFIG } from './constants';

export const getConfig = (payload) => ({
  type: GET_CONFIG,
  payload,
});

export const editConfig = (payload) => ({
  type: EDIT_CONFIG,
  payload,
});
