export const USER_TOKEN_STORAGE_KEY = 'AuthorisationToken';
export const REDIRECT_URL_STORAGE_KEY = 'RedirectTo';
export const USE_LICIOUS_OAUTH = true;

/*Planning roles*/
export const PLANNING_ROLES = Object.freeze({
  PLANNER: 'PLANNER',
  SUPER_PLANNER: 'SUPER_PLANNER',
  PC_USER: {
    BLR_PC_USER: 'BLR_PC_USER',
    HYD_PC_USER: 'HYD_PC_USER',
    MUM_PC_USER: 'MUM_PC_USER',
    NCR_PC_USER: 'NCR_PC_USER',
    KOL_PC_USER: 'KOL_PC_USER',
  },
  CONFIG_MANAGER: 'CONFIG_MANAGER',
});

export const PC_USER_ROLES = Object.values(PLANNING_ROLES.PC_USER);

export const ALLOWED_ROLES = [
  PLANNING_ROLES.PLANNER,
  PLANNING_ROLES.CONFIG_MANAGER,
  PLANNING_ROLES.SUPER_PLANNER,
  ...PC_USER_ROLES,
];
