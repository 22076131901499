import './App.css';
import { useRoutes } from 'react-router-dom';
import { ApplicationRoutes } from './Routing/RouteConfig';

function App() {
  const routes = useRoutes(ApplicationRoutes);

  return <div>{routes}</div>;
}

export default App;
