import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Modal,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { INVENTORY_UPDATE } from '../../pages/ProductionPanning/ProductionPlanMenu/components/InventoryStatus/constants';
import PPButton from '../PPButton';

import styles from './styles.module.scss';
import {
  CITY_INVENTORY_STATUS_TABLE,
  DOWNLOAD_LOG,
  INV_DOWNLOAD_FAILED_SUB_MSG,
  INV_DOWNLOAD_FAILED_TITLE,
} from './constants';
import InventoryStatusIcon from '../../pages/ProductionPanning/ProductionPlanMenu/components/InventoryStatusIcon';
import InventoryStatusTag from './InventoryStatusTag';
import { downloadInventoryLog } from './utils';
import { useSelector } from 'react-redux';
import PPModal from '../PPModal';
import Icon from '../../components/Icon';

export default function PPInventoryStatusModal({
  activePCData,
  open,
  handleClose,
  title,
  accordionItems,
}) {
  const [expanded, setExpanded] = useState(false);
  const [inventoryLogDownloadErr, setInventoryLogDownloadErr] = useState(false);
  const { authUserResponse } = useSelector((state) => state.authReducer);
  const user = authUserResponse?.email_id;
  const scrollContainerRef = useRef();
  const scrollOffsetElem = useRef();

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTo({
          behavior: 'smooth',
          top: scrollOffsetElem.current.offsetTop - 110,
        });
      }, 250);
    }
  }, [expanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    const expanded = isExpanded ? panel : false;
    setExpanded(expanded);
    if (expanded) {
      scrollOffsetElem.current = event.currentTarget.parentElement;
    }
  };

  const handleDownloadInventoryLog = async () => {
    try {
      await downloadInventoryLog(
        activePCData.id,
        user,
        `${activePCData.pcName}_inventory_status.xlsx`,
      );
    } catch (error) {
      setInventoryLogDownloadErr(true);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className={styles.modal_container}>
          <div
            data-testid="close_icon"
            className={styles.modal_close}
            onClick={handleClose}>
            <Icon name="close_icon_stroked" width="20" height="20" />
          </div>
          <div className={styles.modal_header}>
            <h2>{title}</h2>
            <PPButton
              onClick={handleDownloadInventoryLog}
              variant="text"
              className={styles.download_btn}>
              <span>{DOWNLOAD_LOG}</span>
              <Icon name="download_icon_blue" width="20" height="20" />
            </PPButton>
          </div>
          <div ref={scrollContainerRef} className={styles.modal_content}>
            {accordionItems?.map((item) => (
              <Accordion
                data-testid={`accordion_city_${item.cityName}`}
                key={item.cityName}
                expanded={expanded === item.cityName}
                onChange={handleChange(item.cityName)}
                disabled={!item.dpStatusEnable}>
                <AccordionSummary
                  expandIcon={
                    <Icon name="expand_more_icon" width="20" height="12" />
                  }
                  id={`panel${item.cityName}a-header`}
                  className={styles.status_list_item}>
                  <InventoryStatusIcon
                    status={item.status}
                    isDpEnabled={item.dpStatusEnable}
                  />
                  <Typography className={styles.item_title}>
                    <span>{item.cityName}</span> {INVENTORY_UPDATE}
                  </Typography>
                  <Divider className={styles.item_divider} />
                </AccordionSummary>
                <AccordionDetails
                  data-testid={`accordion_details_${item.cityName}`}
                  classes={{ root: styles.accordion_detail__root }}>
                  <table className={styles.inventory_table}>
                    <thead>
                      <tr>
                        {CITY_INVENTORY_STATUS_TABLE.HEADERS.map(
                          (Header, idx) => (
                            <Fragment key={Header.key}>
                              <th className={Header.class}>{Header.text}</th>
                              {idx !==
                                CITY_INVENTORY_STATUS_TABLE.HEADERS.length -
                                  1 && <th></th>}
                            </Fragment>
                          ),
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {item?.categoryConfirmations?.map((category) => (
                        <tr key={category?.category}>
                          <td>{category?.category}</td>
                          <td></td>
                          <td>{category?.skuUpdate}</td>
                          <td></td>
                          <td>
                            <InventoryStatusTag status={category?.status} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </Modal>
      <PPModal
        open={inventoryLogDownloadErr}
        handleClose={() => setInventoryLogDownloadErr(false)}
        icon={<Icon name="warning" height={76} width={78} />}
        modalHeading={INV_DOWNLOAD_FAILED_TITLE}
        subMsg={INV_DOWNLOAD_FAILED_SUB_MSG}
      />
    </>
  );
}
