import { TextField } from '@mui/material';
import style from './style.module.scss';

const EditInput = (props) => (
  <TextField
    {...props}
    InputProps={{
      ...props?.InputProps,
      classes: {
        ...props?.InputProps?.classes,
        root: `${style.text_field} ${props?.InputProps?.classes?.root}`,
      },
      endAdornment: props?.InputProps?.endAdornment,
    }}
    InputLabelProps={{
      ...props?.InputLabelProps,
      classes: {
        root: style.label,
        shrink: style.shrink_label,
        ...props?.InputLabelProps?.classes,
      },
    }}
  />
);
export default EditInput;
