import { format, isValid } from 'date-fns';
/**
 *
 * @param {string} dateString - should be a valid date string for ex. "11/01/2020".
 * @param {string} formatString - should be valid date format string for ex. "dd-MM-yyyy".
 * For more info, check https://date-fns.org/v2.27.0/docs/format
 * @returns string
 */
export const getFormattedDate = (dateString, formatString) => {
  const forecastDate = new Date(dateString);
  if (isValid(forecastDate)) {
    return format(forecastDate, formatString);
  } else {
    return '';
  }
};
