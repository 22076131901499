import { forwardRef } from 'react';

const TableRow = ({ children, ...rest }, ref) => {
  return (
    <tr {...rest} ref={ref}>
      {children}
    </tr>
  );
};

export default forwardRef(TableRow);
