/* Redux Imports(M) */
import {
  AUTH_SERVICE_BASE_URL,
  AUTH_SERVICE_CLIENT_ID,
  AUTH_SERVICE_REDIRECT_URL,
} from '../constants';
import { BASE_URL, API_PREFIX } from './axiosAPIWrapper';

/*Auth Service Url*/
export const SSO_LOGIN_PATH = `${AUTH_SERVICE_BASE_URL}/login?url=${AUTH_SERVICE_REDIRECT_URL}&client_id=${AUTH_SERVICE_CLIENT_ID}`;
export const VERIFY_TOKEN_PATH = `${AUTH_SERVICE_BASE_URL}/verify`;

//Production Planning Url's
const generateUrl = (path, baseUrl = BASE_URL, prefix = API_PREFIX) =>
  new URL(`${prefix}/${path}`, baseUrl).href;
export const UPLOAD_DEMAND_PLAN_URL = generateUrl('demand-forecast/upload');
export const SYNC_DEMAND_PLAN_URL = generateUrl(
  'production-plan/v1/inputs/sync',
);
export const PRODUCTION_PLAN_INPUTS_URL = `${API_PREFIX}/production-plan/v1/inputs`;
export const PRODUCTION_PLAN_TAB_METADATA = `${API_PREFIX}/production-plan/v1/metadata/pc`;
export const SYNC_PRODUCTION_PLAN_TAB_DATA = `${API_PREFIX}/production-plan/v1/revise`;
export const DEMAND_PLAN_DOWNLOAD_URL = generateUrl('demand-forecast/download');

export const PRODUCTION_PLAN_DOWNLOAD_URL = generateUrl(
  'production-plan/v1/download',
);

export const getGoogleSheetUrl = (sheetId) =>
  `https://docs.google.com/spreadsheets/d/${sheetId}`;

export const INVENTORY_UPDATES_URL = `${API_PREFIX}/production-plan/v1/inventory-updates`;
export const getDownloadLogsUrl = (pcId) => {
  const url = new URL(generateUrl('production-plan/v1/dp-audit-logs/download'));
  url.searchParams.set('pcId', pcId);
  return url.href;
};

export const REGENERATE_PRODUCTION_PLAN_URL = generateUrl(
  'production-plan/v1/re-generate',
);

export const GET_PRODUCTION_PLAN_DETAILS = generateUrl(
  'production-plan/v1/details',
);

export const INTERHUB_DISPATCH_PLAN_DOWNLOAD_URL = generateUrl(
  'interhub-plan/download',
);

export const CONFIG_URL = generateUrl(
  'config',
  process.env.REACT_APP_API_PATH,
  '',
);

export const UPLOAD_INTERHUB_DISPATCH_PLAN = generateUrl(
  'interhub-plan/upload',
);

export const DP_FUTURES_SYNCS = generateUrl('dp/futures/sync');
export const PLANNING_MASTER_SYNC = generateUrl('planning/master/sync');
