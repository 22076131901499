import { getDownloadLogsUrl } from '../../utils/serviceUrls';

export const downloadInventoryLog = async (pcId, user, fileName) => {
  try {
    const url = getDownloadLogsUrl(pcId);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'X-REQUESTED-APP': 'production-plan',
        'X-REQUESTED-BY': user,
      },
    });
    if (!response.ok) {
      return Promise.reject('Something went wrong');
    } else {
      const file = await response.blob();
      const fileUrl = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.download = fileName;
      link.href = fileUrl;
      link.click();
      URL.revokeObjectURL(fileUrl); // remove the reference to the file url after download is completed
    }
  } catch (error) {
    // show error here
    return Promise.reject('Something went wrong');
  }
};
