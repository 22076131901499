import { combineReducers } from 'redux';

import headerReducer from './Header';
import productionPlanningReducer from './ProductionPlanning';
import UserReducer from './UserReducer';
import ProductionPlanTabReducer from './ProductionPlanning/ProductionPlan';
import ConfigReducer from './ConfigManagement';

const appReducer = combineReducers({
  header: headerReducer,
  authReducer: UserReducer,
  productionPlanningReducer: productionPlanningReducer,
  productionPlanTabReducer: ProductionPlanTabReducer,
  configReducer: ConfigReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

// export default rootReducer;
export default rootReducer;
