import { createReducer } from '../../utils/createReducer';
import { getFormattedDate } from '../../utils/getFormattedDate';
import {
  GET_CONFIG_ERROR,
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
} from '../saga/ConfigManagement/constants';

const INITIAL_STATE = {
  configList: {
    loading: false,
    error: { id: '', error: '' },
    data: [],
    selectedConfig: {},
  },
};

const ConfigReducer = createReducer(INITIAL_STATE, {
  [GET_CONFIG]: (state) => ({
    ...state,
    configList: {
      ...state.configList,
      loading: true,
      error: '',
      successMessage: '',
    },
  }),

  [GET_CONFIG_ERROR]: (state, { payload }) => ({
    ...state,
    configList: {
      ...state.configList,
      data: [],
      error: { ...payload },
      successMessage: '',
    },
  }),

  [GET_CONFIG_SUCCESS]: (state, { payload }) => {
    let newState = {
      ...state,
      configList: { ...state.configList, loading: false, error: '' },
    };

    if (payload.id === '') {
      return {
        ...newState,
        configList: {
          ...newState.configList,
          data: payload.data.map((data) => ({
            ...data,
            createdAt: getFormattedDate(data.createdAt, 'dd-MMM-yyyy hh:mm a'),
            updatedAt: getFormattedDate(data.updatedAt, 'dd-MMM-yyyy hh:mm a'),
            uid: data.id,
            edit: true,
          })),
        },
      };
    } else {
      return {
        ...newState,
        configList: {
          ...newState.configList,
          selectedConfig: {
            ...payload.data,
            createdAt: getFormattedDate(
              payload?.data?.createdAt,
              'dd-MMM-yyyy hh:mm a',
            ),
            updatedAt: getFormattedDate(
              payload?.data?.updatedAt,
              'dd-MMM-yyyy hh:mm a',
            ),
          },
        },
      };
    }
  },
});

export default ConfigReducer;
