import { takeLatest } from 'redux-saga/effects';
import { AUTH_SERVICE_LOGOUT_PAGE } from './constants';
import { CLEAR_TOKEN } from './constants';
import StorageService from '../../../utils/storageService';
import { USER_TOKEN_STORAGE_KEY } from '../../../pages/LoginProvider/constants';

function* clearTokenSaga(action) {
  StorageService.removeKey('persist:root');
  StorageService.removeKey(USER_TOKEN_STORAGE_KEY);
  window.location.assign(AUTH_SERVICE_LOGOUT_PAGE);
  yield;
}

export default function* watcher() {
  yield takeLatest(CLEAR_TOKEN, clearTokenSaga);
}
