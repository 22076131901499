import Icon from '../../../../../components/Icon';

const statusConfig = {
  low: 'low_indicator',
  medium: 'medium_indicator',
  high: 'high_indicator',
};

const InventoryStatusIcon = ({ status = 'low', isDpEnabled }) => {
  if (isDpEnabled) {
    return (
      <Icon
        name={statusConfig?.[status] ?? statusConfig?.['low']}
        width={26}
        height={26}
      />
    );
  } else {
    return <Icon name="DisabledIndicator" width={26} height={26} />;
  }
};

export default InventoryStatusIcon;
