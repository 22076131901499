import { useRef, useEffect, useCallback, useState } from 'react';
import style from './style.module.scss';

const FileDragandDrop = ({
  onUpload,
  children,
  count = 1,
  formats = [],
  onError = () => {},
}) => {
  const dropRef = useRef();
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFiles = useCallback(
    (files) => {
      //validate file count
      if (files && files.length !== count) {
        return [false, 'File upload count greater than expected'];
      }
      // validate file formats
      if (
        formats &&
        files.some(
          (file) =>
            !formats.some((format) =>
              file.name.toLowerCase().endsWith(format.toLowerCase()),
            ),
        )
      ) {
        return [
          false,
          `Only following file formats are acceptable: ${formats.join(', ')}`,
        ];
      }
      return [true, ''];
    },
    [count, formats],
  );

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      const { files } = e.dataTransfer;
      const [isValid, message] = validateFiles([...files]);
      if (isValid) {
        onUpload([...files]);
      } else {
        onError(message);
      }
    },
    [onError, onUpload, validateFiles],
  );

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  useEffect(() => {
    const dropElement = dropRef?.current;
    dropElement?.addEventListener('dragover', handleDragOver);
    dropElement?.addEventListener('drop', handleDrop);
    dropElement?.addEventListener('dragenter', handleDragEnter);
    dropElement?.addEventListener('dragleave', handleDragLeave);

    return () => {
      if (dropElement) {
        dropElement?.removeEventListener('dragover', handleDragOver);
        dropElement?.removeEventListener('drop', handleDrop);
        dropElement?.removeEventListener('dragenter', handleDragEnter);
        dropElement?.removeEventListener('dragleave', handleDragLeave);
      }
    };
  }, [handleDragEnter, handleDragLeave, handleDrop]);

  const dragClasses = isDragging ? `${style.dragenter}` : '';

  return (
    <div data-testid="file_upload_cmp" className={dragClasses} ref={dropRef}>
      {children}
    </div>
  );
};

export default FileDragandDrop;
