import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LsTab from '../../../components/LsTab';
import styles from '../styles.module.scss';
import localStyles from './styles.module.scss';
import {
  NOTIFY,
  PAGE_TITLE,
  SYNC_BTN,
  TIME_STAMP_CONFIG,
  TAB_CONTENT_TITLE,
  REGENERATE_BTN,
} from './constants';
import TabContent from '../../../components/TabContent';
import PPButton from '../../../common/PPButton';
import {
  fetchInventoryStatusData,
  fetchProductionPlanTabMetadata,
} from '../../../redux/ProductionPlanning/ProductionPlan/actions';
import { PRODUCTION_PLAN } from '../../../constants';
import ProductionPlanMenuUtils from './utils';
//import InventoryStatus from "./components/InventoryStatus";
import PPModal from '../../../common/PPModal';
import {
  ERROR_MESSAGE,
  MODAL_ERR_MSG,
  SUCCESS_HEADING,
} from '../DailyComponent/constants';
import PPSwitch from '../../../common/PPSwitch';
import { setProductionPlanningPlanUpdatedAt } from '../../../redux/ProductionPlanning/actions';
import RBACGuard from '../../../common/Guard/Guard';
import { PLANNING_ROLES } from '../../LoginProvider/constants';
import InventoryStatus from './components/InventoryStatus';
import { REGENERATE_PRODUCTION_PLAN_URL } from '../../../utils/serviceUrls';
import PPRegeneratePlanConfirmationModal from '../../../common/PPRegeneratePlanModal';
import Icon from '../../../components/Icon';

function ProductionPlanMenuPage() {
  const [open, setModalOpen] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const { authUserResponse } = useSelector((state) => state.authReducer);
  const user = authUserResponse?.email_id;
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [subMsg, setSubMsg] = useState('');
  const [active, setActive] = useState(true);
  const [notifyFlag, setNotifyFlag] = useState(true);
  const [syncButtonDisabled, setSyncButtonDisabled] = useState(true);
  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false);
  const [regenerateState, setRegenerateState] = useState({
    error: false,
    success: false,
    loading: false,
  });
  const { loading, error, data } = useSelector(
    (state) => state.productionPlanTabReducer,
  );

  const { list } = data;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [activePcId, setActivePcId] = useState('');

  useEffect(() => {
    if (list.length > 0 && activeTab === 0) {
      setActiveTab(list?.[0]?.value);
    }
  }, [activeTab, list]);

  useEffect(() => {
    dispatch(fetchProductionPlanTabMetadata());
  }, [dispatch]);

  const tabData = useMemo(() => {
    setActive(true);
    setSyncButtonDisabled(true);
    if (!activeTab) return {};
    return list.find((each) => each.id === activeTab) || {};
  }, [activeTab, list]);

  const handleOpenSheet = (id) => {
    setSyncButtonDisabled(false);
    setActivePcId(id);
  };

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleSyncInputData = async () => {
    try {
      setSyncInProgress(true);
      const body = {
        notifyAll: notifyFlag,
        productionPlanId: list.find((pc) => pc.id === activePcId)
          ?.productionPlanId,
      };
      const headers = {
        'X-REQUESTED-APP': PRODUCTION_PLAN,
        'X-REQUESTED-BY': user,
      };
      const res = await ProductionPlanMenuUtils.syncInputData(body, headers);
      if (res) {
        dispatch(
          setProductionPlanningPlanUpdatedAt({
            id: activePcId,
            planUpdated: ProductionPlanMenuUtils.getTimestampDetails(
              res?.planUpdatedAt,
            ),
            inventoryUpdated: ProductionPlanMenuUtils.getTimestampDetails(
              res?.inventoryUpdatedAt,
            ),
            productionPlanId: res?.productionPlanId,
          }),
        );
        setSyncInProgress(false);
        setModalOpen(true);
        setIsSubmitSuccess(true);
        setSubMsg(res?.message);
        setTimeout(() => {
          setModalOpen(false);
        }, 3000);
      }
      setActivePcId('');
    } catch (e) {
      setModalOpen(true);
      setIsSubmitSuccess(false);
      setSubMsg(e);
      setSyncInProgress(false);
    } finally {
      dispatch(
        fetchInventoryStatusData({
          productionPlanId: list.find((pc) => pc.id === activePcId)
            ?.productionPlanId,
          pcId: activePcId,
        }),
      );
    }
  };
  function handleSwitchChange(active) {
    setActive(active);
    setNotifyFlag(active);
  }

  const handleRegenerate = async () => {
    setRegenerateModalOpen(true);
  };

  const regnerateProductionPlan = async () => {
    // TODO: Handle success / errror response.
    try {
      setRegenerateState({ loading: true, error: false, success: false });
      await fetch(REGENERATE_PRODUCTION_PLAN_URL, {
        method: 'POST',
        body: JSON.stringify({
          pc_id: activeTab,
          generated_by: user,
          date: tabData?.planDate,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setRegenerateState({ loading: false, error: false, success: true });
    } catch (error) {
      setRegenerateState({ loading: false, error: true, success: false });
    }
  };

  const closeRegenerateModal = () => {
    setRegenerateModalOpen(false);
    setRegenerateState({ loading: false, error: false, success: false });
  };

  return (
    <div>
      <>
        <h2 className={styles.page_heading}> {PAGE_TITLE} </h2>
        {list?.length > 0 && (
          <>
            <div>
              {!error && (
                <div className={styles.pp_slidebar_section}>
                  <div className={localStyles.container}>
                    <div className={localStyles.left_section}>
                      <LsTab
                        disabled={syncInProgress}
                        list={list || []}
                        value={activeTab || ''}
                        onChange={setActiveTab}
                      />
                      <TabContent
                        title={TAB_CONTENT_TITLE}
                        timeStampConfig={TIME_STAMP_CONFIG}
                        className={localStyles.tab_content}
                        data={tabData}
                        onOpenSheet={handleOpenSheet}
                        regeneratePlan={
                          <RBACGuard
                            allowedRoles={[PLANNING_ROLES.SUPER_PLANNER]}>
                            <PPButton
                              disabled={!tabData.isMainPlanGenerated}
                              variant="text"
                              className={localStyles.regenerate_btn}
                              onClick={handleRegenerate}>
                              {REGENERATE_BTN}
                              <span>
                                <Icon
                                  name="chevron-right"
                                  width="6"
                                  height="11"
                                />
                              </span>
                            </PPButton>
                          </RBACGuard>
                        }
                      />
                      {tabData?.syncEnabled && (
                        <div className={`${localStyles.page_action_container}`}>
                          <RBACGuard
                            allowedRoles={[
                              PLANNING_ROLES.PLANNER,
                              PLANNING_ROLES.CONFIG_MANAGER,
                              PLANNING_ROLES.SUPER_PLANNER,
                            ]}>
                            <div className={localStyles.switch_btn_container}>
                              <span className={localStyles.secondary_button}>
                                <PPSwitch
                                  data-testid="notify_pc_switch"
                                  active={active}
                                  onChange={handleSwitchChange}
                                />
                              </span>
                              <span className={localStyles.notify_txt}>
                                {NOTIFY}
                              </span>
                            </div>
                          </RBACGuard>
                          <span className={localStyles.secondary_button}>
                            <PPButton
                              data-testid="production_plan_sync_btn"
                              variant="contained"
                              classes={{
                                root: syncButtonDisabled
                                  ? localStyles.disabled_button
                                  : localStyles.enabled_button,
                              }}
                              startIcon={
                                <Icon name="sync_icon" width="21" height="20" />
                              }
                              onClick={handleSyncInputData}
                              isAnimating={syncInProgress}
                              disabled={!activePcId || syncInProgress}>
                              <span className={localStyles.sync_txt}>
                                {SYNC_BTN}
                              </span>
                            </PPButton>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={localStyles.right_section}>
                      <InventoryStatus pcId={activeTab} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <PPModal
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                icon={
                  isSubmitSuccess ? (
                    <Icon name="green_tick" height={80} width={80} />
                  ) : (
                    <Icon name="warning" height={76} width={78} />
                  )
                }
                modalHeading={isSubmitSuccess ? SUCCESS_HEADING : MODAL_ERR_MSG}
                subMsg={subMsg}
                success={isSubmitSuccess}
                activeFileSubMsg=""
              />
            </div>
          </>
        )}
        {(list.length === 0 || error) && !loading && (
          <>
            <div className={localStyles.main_err_div}>{ERROR_MESSAGE}</div>
          </>
        )}
      </>
      {regenerateModalOpen && (
        <PPRegeneratePlanConfirmationModal
          open={regenerateModalOpen}
          onClose={closeRegenerateModal}
          productionPlanDate={ProductionPlanMenuUtils.formatDate(
            new Date(tabData?.planDate),
            'dd-MMM-yyyy',
          )}
          regeneratePlan={regnerateProductionPlan}
          regenerateState={regenerateState}
        />
      )}
    </div>
  );
}

export default ProductionPlanMenuPage;
