import { PRODUCTION_PLAN } from '../../../constants';
import { PLANNING_MASTER_SYNC } from '../../../utils/serviceUrls';
import { SUCCESS } from '../DailyComponent/constants';

export const planningMasterSync = async ({ token, uploadedBy, data = {} }) => {
  const url = PLANNING_MASTER_SYNC;
  try {
    const response = await fetch(url, {
      headers: {
        token,
        'X-REQUESTED-APP': PRODUCTION_PLAN,
        'X-REQUESTED-BY': uploadedBy,
        'content-type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const data = await response.json();
      if (response.status === SUCCESS) {
        const successsMap = data?.data?.reduce((acc, curr) => {
          acc[curr.inputId] = curr;
          return acc;
        }, {});
        return {
          ...data,
          data: successsMap,
        };
      } else {
        return data;
      }
    } else {
      return Promise.reject(response.json());
    }
  } catch (error) {
    return error;
  }
};
