const useTableSearch = (tableData, search, searchAttributes) => {
  const searchs = tableData.filter((row) => {
    return searchAttributes.reduce((acc, attr) => {
      const isMatch = row?.[attr]
        ?.toString()
        ?.toLowerCase()
        .includes(search?.toLowerCase());
      acc = acc && isMatch;
      return acc;
    }, true);
  });
  if (search) {
    return searchs;
  }
  return tableData;
};

export default useTableSearch;
