import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PPButton from '../../../../../common/PPButton';
import PPInventoryStatusModal from '../../../../../common/PPInventoryStatusModal';
import { fetchInventoryStatusData } from '../../../../../redux/ProductionPlanning/ProductionPlan/actions';
import {
  selectInventoryStatusData,
  selectInventoryStatusError,
  selectInventoryStatusLoading,
} from '../../../../../redux/ProductionPlanning/ProductionPlan/selectors';
import { EXPAND, SKELETON_ARR, TITLE } from './constants';
import styles from './styles.module.scss';
import InventoryStatusIcon from '../InventoryStatusIcon';
import { Skeleton } from '@mui/material';
import Icon from '../../../../../components/Icon';

function InventoryStatus({ pcId }) {
  const dispatch = useDispatch();
  const [isExpandView, setIsExpandView] = useState(false);
  const { data } = useSelector((state) => state.productionPlanTabReducer);
  const inventoryStatusData = useSelector(selectInventoryStatusData);
  const inventoryStatusLoading = useSelector(selectInventoryStatusLoading);
  const inventoryStatusError = useSelector(selectInventoryStatusError);

  const activePCData = data?.list?.find((pc) => pc.id === pcId);

  useEffect(() => {
    if (activePCData?.id && activePCData?.productionPlanId) {
      dispatch(
        fetchInventoryStatusData({
          pcId: activePCData.id,
          productionPlanId: activePCData.productionPlanId,
        }),
      );
    }
  }, [activePCData?.id, activePCData?.productionPlanId, dispatch, pcId]);

  const handleCloseExpandView = () => {
    setIsExpandView(false);
  };

  const handleExpand = () => {
    setIsExpandView(true);
  };

  const dpEnabled = inventoryStatusData.reduce(
    (acc, city) => (acc ||= acc ?? city.dpStatusEnable),
    null,
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>{TITLE}</h2>
        {!inventoryStatusError && (
          <PPButton
            variant="text"
            className={styles.expand_btn}
            onClick={handleExpand}
            disabled={inventoryStatusLoading || !dpEnabled}>
            {EXPAND}
            <span>
              <Icon name="chevron-right" width="6" height="11" />
            </span>
          </PPButton>
        )}
      </div>
      <div className={styles.status_container}>
        {!inventoryStatusLoading && (
          <>
            {inventoryStatusData.map((each) => (
              <div className={styles.label} key={each.cityName}>
                <InventoryStatusIcon
                  status={each.status}
                  isDpEnabled={each.dpStatusEnable}
                />
                <span>
                  <span className={styles.city_name}>{each.cityName}</span>{' '}
                  Inventory Update
                </span>
              </div>
            ))}
            {inventoryStatusError && (
              <span className={styles.label}>{inventoryStatusError}</span>
            )}
          </>
        )}
        {inventoryStatusLoading && (
          <>
            {SKELETON_ARR.map((_, idx) => (
              <div key={idx} className={styles.label}>
                <Skeleton className={styles.skeleton} width={25} height={25} />
                <Skeleton className={styles.skeleton} width={248} height={25} />
              </div>
            ))}
          </>
        )}
      </div>
      <PPInventoryStatusModal
        activePCData={activePCData}
        open={isExpandView}
        handleClose={handleCloseExpandView}
        title={TITLE}
        accordionItems={inventoryStatusData}
      />
    </div>
  );
}

export default InventoryStatus;
