import { React, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PPModal from '../../../common/PPModal';
import PPUploadComponent from '../../../common/PPUploadComponent';
import { getProductionPlanInputs } from '../../../redux/saga/ProductionPlanning/actions';
import { getGoogleSheetUrl } from '../../../utils/serviceUrls';
import StorageService from '../../../utils/storageService';
import { USER_TOKEN_STORAGE_KEY } from '../../LoginProvider/constants';
import styles from './style.module.scss';
import {
  FILE_NAME,
  BROWSE_FILE,
  SUBMIT,
  SYNC_BTN,
  MODAL_ERR_MSG,
  SUCCESS,
  BUFFER_SPREADSHEET,
  EDIT_FILE,
  DAILY,
  ERROR_MESSAGE,
  SHEET_UNAVAILABLE,
  SUCCESS_HEADING,
  SYNC_UPLOAD_MSG,
  FAILED,
  INTERHUB_DISPATCH_PLAN_UPLOAD,
  FILE_NOT_UPLOADED,
} from './constants';
import {
  demandFileSync,
  demandPlanFileUpload,
  DPformatDate,
  formatDataTill,
  formatDate,
  interhubPlanUpload,
} from './utils';
import PPButton from '../../../common/PPButton';
import PPAttentionModal from '../../../common/PPAttentionModal';
import {
  ATTENTION_MODAL_HEADING,
  ATTENTION_MODAL_SUBMSG,
} from '../MasterComponent/constants';
import Icon from '../../../components/Icon';

const DailyComponent = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [open, setModalOpen] = useState(false);
  const [subMsg, setSubMsg] = useState('');
  const [uploading, setUploading] = useState(false);
  const [changeFileColor, setFileColor] = useState(false);
  const [browseFile, setBrowseFile] = useState(false);
  const [activeFile, setActiveFile] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [dataTill, setDataTill] = useState('');

  const token = StorageService.getValue(USER_TOKEN_STORAGE_KEY);
  const { authUserResponse } = useSelector((state) => state?.authReducer);
  const name = authUserResponse?.email_id;
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [syncButtonDisabled, setSyncButtonDisabled] = useState(true);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [dynamicSheetErr, setDynamicSheetErr] = useState('');
  const [succArrList, setSuccArrList] = useState({});
  const [fileCount, setFileCount] = useState();
  const [attentionModal, setAttentionModal] = useState(false);
  const [fullSuccErr, setFullSuccErr] = useState(false);
  const [dpActiveFile, setDpActiveFile] = useState('');

  const [interHubActiveFile, setInterHubActiveFile] = useState('');
  const [changeIHFileColor, setIHFileColor] = useState(false);
  const [IHSelectedFile, setIHSelectedFile] = useState('');
  const [IHSubmitDisabled, setIHSubmitDisabled] = useState(true);
  const [IHUploading, setIHUploading] = useState(false);
  const [IHUpdatedAt, setIHUpdatedAt] = useState('');
  const [modalHeading, setModalHeading] = useState('');

  const editedFileArr = useRef([]);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
    setIsSubmitSuccess(false);
    setSubMsg('');
    setActiveFile('');
    setDpActiveFile('');
  };

  const handleAttentionModalClose = () => setAttentionModal(false);

  const {
    productionPlanInputsData,
    productionPlanInputsLoading,
    productionPlanInputsError,
  } = useSelector((rootReducer) => rootReducer.productionPlanningReducer);
  const {
    demandPlanMetadata: demandPlanMetaData,
    inputMetadata,
    interHubInfo = {},
  } = productionPlanInputsData;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductionPlanInputs());
  }, [updatedAt, dataTill, dispatch]);

  function handleFileUpload(file, fileName) {
    var currentFile = file[0]?.name;
    setActiveFile('');
    setDpActiveFile(currentFile);
    setSubmitButtonDisabled(false);
    if (fileName === currentFile) {
      setFileColor(false);
    }
    setFileColor(false);
    setSelectedFile(file[0]);
  }

  function handleInterhubFile(file, fileName) {
    var currentFile = file[0]?.name;
    setActiveFile('');
    setInterHubActiveFile(currentFile);
    setIHSubmitDisabled(false);
    if (fileName === currentFile) {
      setIHFileColor(false);
    }
    setIHFileColor(false);
    setIHSelectedFile(file[0]);
  }

  const submitDemandPlanUploadFile = async () => {
    let formData = new FormData();
    let uploaded_by = name;
    formData.append('file', selectedFile);
    formData.append('uploadedBy', uploaded_by);
    setUploading(true);
    setSubmitButtonDisabled(true);
    setBrowseFile(true);
    setFileColor(false);
    setModalHeading('');
    //upload demand plan api
    try {
      const res = await demandPlanFileUpload(token, uploaded_by, formData);

      if ([SUCCESS, FAILED].includes(res?.status?.toLowerCase())) {
        setSelectedFile(null);
        setUploading(false);
        setUpdatedAt('');
        setDataTill('');
        setFileColor(true);
        setDpActiveFile(res?.demandPlanMetadata?.fileName);
        setIsSubmitSuccess(true);
        setFullSuccErr(true);

        setBrowseFile(false);
        setModalHeading(res?.message);
        setSubmitButtonDisabled(true);
        setModalOpen(true);
        setTimeout(() => {
          handleClose();
        }, 3000);
        //Updating file details
        setUpdatedAt(res?.demandPlanMetadata?.lastUpdatedAt);
        setDataTill(res?.demandPlanMetadata?.availableTill);
        dispatch(getProductionPlanInputs());
      } else {
        setModalHeading(MODAL_ERR_MSG);
        setFullSuccErr(true);
        setIsSubmitSuccess(false);
        setModalOpen(true);
        setSubMsg(res?.message || res?.errors?.[0]?.message);
        setBrowseFile(false);
        setUploading(false);
        setDpActiveFile('');
        setSelectedFile(null);
      }
    } catch (err) {
      setModalHeading(MODAL_ERR_MSG);
      setFullSuccErr(true);
      setModalOpen(true);
      setSubMsg('');
      setFileColor(false);
      setIsSubmitSuccess(false);
    }
  };

  function handleEditFileOpen(sheetId, fileName, id) {
    setActiveFile(fileName);
    setSyncButtonDisabled(false);
    if (sheetId) {
      window.open(getGoogleSheetUrl(sheetId), '_blank');
    } else {
      setDynamicSheetErr(SHEET_UNAVAILABLE);
    }
    const item =
      editedFileArr?.current &&
      editedFileArr?.current?.some(
        (each) => each?.toString() === id?.toString(),
      );
    if (item) {
      return;
    } else {
      editedFileArr?.current?.push(id);
    }
  }

  function syncEditedFile(fileName) {
    let uploaded_by = name;
    let arrLength = editedFileArr.current.length - 1;
    setSyncInProgress(true);
    setDpActiveFile('');
    setSyncButtonDisabled(true);
    setModalHeading('');
    setSubMsg('');
    demandFileSync(token, uploaded_by, {
      inputIds: editedFileArr.current,
    })
      .then((res) => {
        if (res?.status === SUCCESS) {
          setActiveFile(fileName);
          setFileCount(arrLength);
          editedFileArr.current = [];
          setSyncInProgress(false);
          setUploading(false);
          setSyncButtonDisabled(false);
          setSyncButtonDisabled(true);
          setSuccArrList(res?.data);
          dispatch(getProductionPlanInputs());
          if (res?.data) {
            const hasSuccess = Object.values(res?.data).some(
              (each) => each.status === SUCCESS,
            );
            const hasError = Object.values(res?.data).some(
              (each) => each.status !== SUCCESS,
            );

            if (hasSuccess && hasError) {
              setFullSuccErr(false);
              setModalOpen(false);
              setAttentionModal(true); // show attention
            }
            if (hasSuccess && !hasError) {
              setActiveFile(fileName);
              setIsSubmitSuccess(true);
              setModalOpen(true);
              setModalHeading(SUCCESS_HEADING);
              setFullSuccErr(true); // show success
              setTimeout(() => {
                setModalOpen(false);
              }, 3000);
            }
            if (!hasSuccess && hasError) {
              setModalOpen(true);
              setSubMsg(res?.message || res?.errors?.[0]?.message);
              setIsSubmitSuccess(false);
              setModalHeading(MODAL_ERR_MSG);
              setFullSuccErr(true); // show error
            }
          }
        } else {
          setModalHeading(MODAL_ERR_MSG);
          setFullSuccErr(true);
          setSubMsg('');
          setModalOpen(true);
          setSyncButtonDisabled(false);
          setSyncInProgress(false);
        }
      })
      .catch((error) => {
        setSyncInProgress(false);
        setSubMsg(error?.message);
        setModalHeading(MODAL_ERR_MSG);
        setSyncButtonDisabled(false);
      });
  }

  function getLastUpdatedAt(dateObj) {
    return formatDate(dateObj);
  }

  function getDPLastUpdatedAt(dateObj) {
    return DPformatDate(dateObj);
  }

  function getDataTillDate(dateObj) {
    return formatDataTill(dateObj);
  }

  function getDailyInputMetaData() {
    return inputMetadata?.filter((data) => data?.type === DAILY) || [];
  }
  function getSheetErrorMessage(sheetId) {
    if (dynamicSheetErr) return dynamicSheetErr;
    if (succArrList[sheetId] && succArrList[sheetId].status === FAILED)
      return succArrList[sheetId].message;
    return '';
  }

  const onUpload = (files) => {
    handleFileUpload(files, demandPlanMetaData?.fileName);
  };

  const submitInterhubUploadFile = async () => {
    let formData = new FormData();
    let uploaded_by = name;
    formData.append('file', IHSelectedFile);
    //formData.append("uploadedBy", uploaded_by);
    setIHUploading(true);
    setIHSubmitDisabled(true);
    setBrowseFile(true);
    setIHFileColor(false);
    setModalHeading('');
    //upload demand plan api
    try {
      const res = await interhubPlanUpload(token, uploaded_by, formData);

      if (res?.status === SUCCESS) {
        setIHSelectedFile(null);
        setIHUploading(false);
        setIHUpdatedAt('');
        setIHFileColor(true);
        setInterHubActiveFile(res?.interHubInfo?.fileName);
        setIsSubmitSuccess(true);
        setFullSuccErr(true);
        setBrowseFile(false);
        setSubMsg(res?.message);
        setIHSubmitDisabled(true);
        setModalOpen(true);
        setTimeout(() => {
          handleClose();
        }, 3000);
        //Updating file details
        setIHUpdatedAt(res?.interHubInfo?.lastUpdatedAt);
        dispatch(getProductionPlanInputs());
      } else {
        setModalHeading(FILE_NOT_UPLOADED);
        setFullSuccErr(true);
        setIsSubmitSuccess(false);
        setModalOpen(true);
        setSubMsg(res?.message || res?.errors?.[0]?.message);
        setBrowseFile(false);
        setIHUploading(false);
        setInterHubActiveFile('');
        setIHSelectedFile(null);
      }
    } catch (err) {
      setModalHeading(FILE_NOT_UPLOADED);
      setFullSuccErr(true);
      setModalOpen(true);
      setSubMsg('');
      setIHUploading(false);
      setIHFileColor(false);
      setIsSubmitSuccess(false);
    }
  };

  const onInterhubDragnDrop = (files) => {
    handleInterhubFile(files, interHubInfo?.filename);
  };

  return (
    <>
      <div data-testid="daily_tab_content" className={styles.main_container}>
        {productionPlanInputsData?.status === SUCCESS && (
          <>
            <PPUploadComponent
              data-testid="demand_forecast_upload"
              title={FILE_NAME}
              uploadBoxTitle={
                dpActiveFile ? dpActiveFile : demandPlanMetaData?.fileName
              }
              showSubmit={true}
              firstBtnLabel={BROWSE_FILE}
              secondBtnLabel={SUBMIT}
              firstIcon={<Icon name="upload_file" width="20" height="20" />}
              uploading={uploading}
              handleFileUpload={(...params) =>
                handleFileUpload(...params, demandPlanMetaData?.fileName)
              }
              submitUploadFile={submitDemandPlanUploadFile}
              selectedFile={selectedFile}
              uploadFile={true}
              updatedAt={getDPLastUpdatedAt(demandPlanMetaData?.lastUpdatedAt)}
              dataTill={
                dataTill
                  ? getDataTillDate(dataTill)
                  : getDataTillDate(demandPlanMetaData?.availableTill)
              }
              turnBluecolor={changeFileColor}
              fileButtonDisabled={browseFile}
              submitButtonDisabled={submitButtonDisabled}
              drag
              dragProps={{ count: 1, formats: ['.xlsx', '.xls'], onUpload }}
            />
            <div className={styles.submit_div}>
              <PPButton
                data-testid="demand_forecast_upload_btn"
                variant="contained"
                classes={{
                  root: submitButtonDisabled
                    ? styles.disabled_button
                    : styles.enabled_button,
                }}
                onClick={submitDemandPlanUploadFile}
                disabled={submitButtonDisabled}>
                {SUBMIT}
              </PPButton>
            </div>

            {getDailyInputMetaData()?.map((data) => (
              <PPUploadComponent
                data-testid="daily_input_metadata"
                key={data?.id}
                title={BUFFER_SPREADSHEET}
                uploadBoxTitle={data?.title}
                firstBtnLabel={EDIT_FILE}
                showSubmit={true}
                firstIcon={
                  <Icon name="edit_file_icon" width="22" height="22" />
                }
                handleEditFileOpen={() =>
                  handleEditFileOpen(data.sheetId, data?.title, data?.id)
                }
                updatedAt={getLastUpdatedAt(data?.lastUpdatedAt)}
                turnBluecolor={
                  succArrList[data?.id] &&
                  succArrList[data?.id].status === SUCCESS
                }
                dynamicSheetErrorMsg={getSheetErrorMessage(data?.id)}
                dynamicSheetSuccessMsg={
                  succArrList[data?.id] && succArrList[data?.id].message
                }
              />
            ))}
            {demandPlanMetaData && (
              <div className={styles.submit_div}>
                <PPButton
                  data-testid="daily_input_sync_btn"
                  variant="contained"
                  classes={{
                    root: syncButtonDisabled
                      ? styles.disabled_button
                      : styles.enabled_button,
                  }}
                  startIcon={<Icon name="sync_icon" width="21" height="20" />}
                  onClick={() => syncEditedFile(activeFile)}
                  isAnimating={syncInProgress}
                  disabled={syncButtonDisabled}>
                  {' '}
                  <span className={styles.sync_txt}>{SYNC_BTN}</span>
                </PPButton>
              </div>
            )}
            <PPUploadComponent
              data-testid="interhub_dispatch_plan_upload"
              title={INTERHUB_DISPATCH_PLAN_UPLOAD}
              uploadBoxTitle={
                interHubActiveFile ? interHubActiveFile : interHubInfo?.fileName
              }
              showSubmit={true}
              firstBtnLabel={BROWSE_FILE}
              secondBtnLabel={SUBMIT}
              firstIcon={<Icon name="upload_file" width="20" height="20" />}
              uploading={IHUploading}
              handleFileUpload={(...params) =>
                handleInterhubFile(...params, interHubInfo?.fileName)
              }
              submitUploadFile={submitInterhubUploadFile}
              selectedFile={IHSelectedFile}
              uploadFile={true}
              updatedAt={getDPLastUpdatedAt(
                interHubInfo?.lastUpdatedAt || IHUpdatedAt,
              )}
              dataTill={''}
              turnBluecolor={changeIHFileColor}
              fileButtonDisabled={false}
              submitButtonDisabled={IHSubmitDisabled}
              drag
              dragProps={{
                count: 1,
                formats: ['.xlsx'],
                onUpload: onInterhubDragnDrop,
              }}
            />
            <div className={styles.submit_div}>
              <PPButton
                data-testid="demand_forecast_upload_btn"
                variant="contained"
                classes={{
                  root: IHSubmitDisabled
                    ? styles.disabled_button
                    : styles.enabled_button,
                }}
                onClick={submitInterhubUploadFile}
                disabled={IHSubmitDisabled}>
                {SUBMIT}
              </PPButton>
            </div>
            {fullSuccErr && (
              <div>
                <PPModal
                  data-testid="full_succ_error"
                  open={open}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  icon={
                    isSubmitSuccess ? (
                      <Icon name="green_tick" height={80} width={80} />
                    ) : (
                      <Icon name="warning" height={76} width={78} />
                    )
                  }
                  modalHeading={modalHeading}
                  success={isSubmitSuccess}
                  subMsg={subMsg}
                  activeFileSubMsg={
                    activeFile
                      ? activeFile +
                        (fileCount > 0 ? '...(+' + fileCount + ') ' : ' ') +
                        SYNC_UPLOAD_MSG
                      : ''
                  }
                  fileCount={fileCount}
                />
              </div>
            )}
            <div>
              {attentionModal && (
                <PPAttentionModal
                  data-testid="attention_modal"
                  open={attentionModal}
                  handleClose={handleAttentionModalClose}
                  icon={<Icon name="attentionIcon" height={76} width={78} />}
                  modalHeading={ATTENTION_MODAL_HEADING}
                  subMsg={ATTENTION_MODAL_SUBMSG}
                />
              )}
            </div>
          </>
        )}
        {(productionPlanInputsError ||
          (productionPlanInputsData?.status !== SUCCESS &&
            !productionPlanInputsLoading)) && (
          <div className={styles.main_err_div}>{ERROR_MESSAGE}</div>
        )}
      </div>
    </>
  );
};

export default DailyComponent;
