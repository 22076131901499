import { CONFIG_PAGE_PATH } from '../../Routing/paths';
import {
  PC_USER_ROLES,
  PLANNING_ROLES,
} from '../../pages/LoginProvider/constants';

export const PRODUCTION_PLANNING_TABS_CONFIG = {
  DATA_INPUT: {
    value: 'DATA_INPUT',
    url: '/production-planning/data-input',
    label: 'Data Input',
    roles: [PLANNING_ROLES.PLANNER, PLANNING_ROLES.SUPER_PLANNER],
  },
  PRODUCTION_PLAN: {
    value: 'PRODUCTION_PLAN',
    url: '/production-planning/production-plan',
    label: 'Production Plan',
    roles: [
      PLANNING_ROLES.PLANNER,
      PLANNING_ROLES.SUPER_PLANNER,
      ...PC_USER_ROLES,
    ],
  },
  ARCHIVES: {
    value: 'ARCHIVES',
    url: '/production-planning/archives',
    label: 'Archives',
    roles: [
      PLANNING_ROLES.PLANNER,
      ...PC_USER_ROLES,
      PLANNING_ROLES.SUPER_PLANNER,
    ],
  },
  CONFIG: {
    value: 'CONFIG MANAGEMENT',
    url: CONFIG_PAGE_PATH,
    label: 'Config Management',
    roles: [PLANNING_ROLES.CONFIG_MANAGER],
  },
  // will be enabled post QA sign off and API integration
  // RM_PLAN: {
  //   value: "RM_PLAN",
  //   url: "/production-planning/rm-plan",
  //   label: "RM Plan",
  // },
};
