import style from './style.module.scss';

export const TablePagination = ({
  currentPage,
  pages,
  dataLength,
  className,
  handlePageClick,
}) => {
  if (!dataLength) return null;
  const pageNumbers = [...Array(pages + 1).keys()].slice(1);
  const isCurrentPage = (page) => page === currentPage + 1;
  return (
    <div className={`${style.table_pagination} ${className}`}>
      {pageNumbers.map((page) => (
        <button
          onClick={() => handlePageClick(page)}
          key={page}
          className={`${style.page_button} ${
            isCurrentPage(page) ? style.current_page : ''
          }`}>
          {page}
        </button>
      ))}
    </div>
  );
};
