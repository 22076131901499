export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again!';

export const DEMAND_ANALYSIS = {
  FILTERING_LENGTH: 4,
  DEFAULT_MEASURES: ['3', '5'],
  DEFAULT_UNIT: '2',
  MONTH_COLUMN: 6,
  DAILY_COLUMN: 14,
  WEEKLY_COLUMN: 8,
  DEFAULT_INV_MEASURES: ['3'],
  WEEK_IN_YEAR: 52,
  MONTH_IN_YEAR: 12,
  URL: '/demand-planning',
  CUSTOM_TIMELINE: 'CUSTOM TIMELINE',
  VIEW_TYPE: 'VIEW TYPE',
  CUSTOM_SEARCH_WIDTH: '60%',
};
export const calendarTabs = [
  { id: 1, name: 'Daily', value: 'DAILY' },
  { id: 2, name: 'Weekly', value: 'WEEKLY' },
  { id: 3, name: 'Monthly', value: 'MONTHLY' },
];

export const STAGE_CODE_URL_MAPPING = {
  PAN: 'pan-India',
  IPF: 'input-file',
  MDR: 'model-run',
  MER: 'merchant-forecast',
  COF: 'Chennai-override-forecast',
  KCF: 'kebab-cold-cuts-forecast',
  PNF: 'pre-order-sku-forecast',
  RTS: 'revenue-target-s&op',
  RET: 'revenue-target-s&op?child-stage=revenue-target',
  CTN: 'revenue-target-s&op?child-stage=contribution',
  NCF: 'new-cities-forecast',
  RTC: 'recent-trends-r3',
  INB: 'inventory-buffer',
  POR: 'pre-order-revision',
  STC: 's&op-corrections-r7',
  TSC: 'sku-corrections',
  RTR: 'revenue-target-s&op-2',
  CBN: 'revenue-target-s&op-2?child-stage=contribution-2',
  RTG: 'revenue-target-s&op-2?child-stage=revenue-target-2',
  STN: 's&op-corrections-r7-2',
  IBO: 'inventory-buffer-2',
  SPC: 's&op-prod_day_cat-contribution-correction-r1',
  SCC: 's&op-city_cat_day-correction-r2',
  OFF: 'offline-forecast',
  FNO: 'final-override',
  FNOO: 'final-override-2',
};

export const MONTHS_MAPPING = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};
export const filters = [
  {
    id: 1,
    name: `City`,
    value: 0,
  },
  {
    id: 2,
    name: `Hubs`,
    value: 0,
  },
  {
    id: 3,
    name: `Categories`,
    value: 0,
  },
  {
    id: 4,
    name: `Products`,
    value: 0,
  },
];

export const filtersOverride = [
  {
    id: 1,
    name: `City`,
    value: 0,
  },
  {
    id: 2,
    name: `Categories`,
    value: 0,
  },
  {
    id: 3,
    name: `Products`,
    value: 0,
  },
];

export const radioListByNameById = [
  { id: '1', name: 'By Name', value: 'name' },
  { id: '2', name: 'By ID', value: 'id' },
];
export const FIXED_STRING_SIDEBAR = {
  city: 'City',
  hub: 'Hubs',
  categories: 'Categories',
  products: 'Products',
  unit: {
    quantity: 'QUANTITY',
    revenue: 'REVENUE',
  },
  default_selected_city_radio: 'name',
  daily: 'DAILY',
  weekly: 'WEEKLY',
  monthly: 'MONTHLY',
  GRAPH_TITLE: 'Licious Forecasting',
  GRAPH_SUB_TITLE_REVENUE: 'In Revenue',
  GRAPH_SUB_TITLE_QUANTITY: 'In Quantity',
  DAY: 'Daily',
  WEEK: 'Weekly',
  MONTH: 'Monthly',
  SALE_FORECAST: 'Sale Forecast',
  INVENTORY_FORECAST: 'Inventory Forecast',
  ACTUAL_SALE: 'Actual Sale',
};
export const FILTER_CLEAR = {
  city_clear: 'city_clear',
  hub_clear: 'hub_clear',
  product_clear: 'product_clear',
  category_clear: 'category_clear',
  clear_meassure: 'clear_meassure',
};
export const FORECAST_NOTIFICATION = '';
export const DATE_ERROR_MESSAGE = 'Please provide the valid date';
export const FREEZE_TIMELINE_MESSAGE = `Do you wish to freeze the timeline? 
  You will no longer be able to edit the stages`;
export const stageSuccessMessage = (title) =>
  `${title} is updated successfully!`;
export const stageFailedMessage = (title) =>
  `${title} is failed.You will have to override and sync again.`;
export const stageInprogressMessage = (title) =>
  `${title} is taking longer time than expected.Sync is in progress.
  Please click on sync again !!`;
export const timelineRestartMessage = (title) =>
  `Are you sure you want to restart timeline from ${title}`;
export const DEMAND_ANALYSIS_TIMELINE_ERROR = 'Timeline API failed.';
// Will have it from API
export const OVERRIDE_MANAGER_STATUS = {
  FAILED: 'FAILED',
  RESET: 'RESET',
  INPROGRESS: 'INPROGRESS',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  INITIALIZED: 'INITIALIZED',
  PENDING: 'PENDING',
  SYSTEM: 'System',
  MANUAL: 'Manual',
};
export const OVERRIDE_MANAGER_DEFAULT_VALUES = {
  TIMEOUT: 10000,
  COUNTER: 5,
  UNIT: 'quantity',
  CHANNEL: 'online',
  CLASSIFICATION: 'head',
  MON_FRI_FINAL_STAGE_CODE: 'FNO',
  FRI_SUN_FINAL_STAGE_CODE: 'FNOO',
  MON_FRI_OFFLINE_STAGE_CODE: 'OFF',
};

export const DATE_FORMAT_STANDARD = 'DD-MM-YYYY';
export const DATE_FORMAT_GET_DAY = 'ddd';
export const DATE_FORMAT_GET_DATE = 'DD';
export const DATE_FORMAT_GET_YEAR_MONTH = 'YYYY-MM';
export const NO_USER = 'NA';
export const DATE_FORMAT_DEMAND_ANALYSIS_API = 'YYYY-MM-DD';

export const OVERRIDE_MANAGER_POPUP_TYPE = {
  OVERRIDE_INPROGRESS: 'override-inProgress',
  OVERRIDE_SUCCESS: 'override-success',
  DEFAULT_INPROGRESS: 'inProgress',
  DEFAULT_SUCCESS: 'success',
  DEFAULT_FAILED: 'failed',
};

export const OVERRIDE_MANAGER_BUTTON_TITLE = {
  SYNC: 'Sync',
  OVERRIDE: 'OverRide',
  EDIT: 'Edit',
  UPLOAD: 'UPLOAD File',
  DOWNLOAD: 'download current version',
  SELECT_UPLOAD: 'Select File',
  CUSTOMISE: 'Customise',
};

export const OVERRIDE_MANAGER_FIXED_STRING = {
  REDIRECT_TEXT: 'Redirect',
};

export const APPLICATION_ROUTE = {
  ROOT: '/',
  LOGIN: '/login',
  DEMAND_ANALYSIS: '/demand-analysis',
  OVERRIDE_MANAGER: '/override-manager/*',
  EVENT_CALENDAR: '/event-calendar',
  SSO_ROUTE: '/sso/redirect',
  NOT_AUTHORIZED: '/not-authorized',
  PRODUCTION_PLANNING: '/production-planning',
};

export const DOMAIN_NAME_ROUTE = {
  DEMAND_ANALYSIS: 'demand-analysis',
  OVERRIDE_MANAGER: 'override-manager',
  EVENT_CALENDAR: 'event-calendar',
  PRODUCTION_PLANNING: 'production-planning',
};

export const AUTH_SERVICE_REDIRECT_URL =
  process.env.REACT_APP_AUTH_SERVICE_REDIRECT_URL;
export const AUTH_SERVICE_CLIENT_ID = 'planning-forecast';
export const AUTH_SERVICE_BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

export const PRODUCTION_PLAN = 'production_plan';
