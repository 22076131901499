import classNames from 'classnames';
import styles from './style.module.scss';
import Icon from '../../components/Icon';

function PPSwitch({ active, onChange, ...rest }) {
  const handleChange = () => {
    onChange(!active);
  };

  return (
    <label data-testid={rest?.['data-testid']} className={styles.switch}>
      <input type="checkbox" checked={active} onChange={handleChange} />
      <span className={classNames(styles.slider, styles.round)} />
      <span
        className={classNames(styles.content, {
          [styles.left]: !active,
          [styles.right]: active,
        })}>
        {active && (
          <Icon
            name="ToggleCheckIcon"
            data-testid="toogle_check_icon"
            width={14}
            height={10}
          />
        )}
        {!active && (
          <Icon
            name="ToggleCrossIcon"
            data-testid="toggle_cross_icon"
            width={12}
            height={12}
          />
        )}
      </span>
    </label>
  );
}

export default PPSwitch;
